
    import { Options, Vue } from 'vue-class-component';
    import Common from '@/services/common.js';
    import moment from 'moment';  

    @Options({ 
        props: {
            fum_id:     Number,  
        },  
    })
    
    export default class SecuritiesBasic extends Vue { 
      
        fum_id;
        local_storage:any; 
        basic:any={}; 
        data () {      
          return {    
            fum_id  :this.fum_id, 
            basic   :this.basic, 
          }
     
        }   

       
        created() {    
            this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));    
            return true;
        }
        mounted() {
            this.fum_id;     
            this.get_basic_info();
        }  

        get_basic_info() { 
            Common.get_basic_info(this.local_storage['access-token'],this.fum_id)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        this.basic = response.data.basic; 
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
    }
