
    import { Options, Vue } from 'vue-class-component';   
    import App from '../App.vue';  
    import Common from '@/services/common.js';   
    import Basic from '@/components/Basic.vue';      
    @Options({
        components: {  
            Basic 
        } 
    })

  export default class Onboard extends Vue { 
    load_on:any     =   0; 
    STORAGE_KEY     =   "caprock-local";     
    data () {     
        return {   
            load_on:this.load_on,  
        }
    }    
    beforeCreate(){
        this.load_on=1;
    } 
    created() {     
        setTimeout(() => this.load_on=0, 500);   
        localStorage.removeItem(this.STORAGE_KEY);   

        setTimeout(() => this.$router.push('/sign-in'), 7000);  
        document.title = 'Onboard || Caprock Merchant Platform';   
        return true;
    }  
  } 
