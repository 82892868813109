
  import { Options, Vue } from 'vue-class-component';   
  import Common from '@/services/common.js';  
  import App from '../App.vue';  
  import $ from 'jquery';  
  import 'bootstrap';
  import Basic from '@/components/Basic.vue';   
  import { useRoute } from 'vue-router'
  import moment from 'moment';
 
  import Axios from "axios";
  @Options({
    components: {  
      Basic
    }, 
  })

  export default class ImAgreement extends Vue { 
    privacy ={}; 
    privacy_data =''; 
    STORAGE_KEY = "caprock-local";   
    data () {    
      Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
      this.get_privacys(); 
       
      return {
        privacy:this.privacy ,
        privacy_data:this.privacy_data, 
      }
    }   
  
    get_privacys(){ 
      Common.get_im_agreement()
      .then(response => { 
        if(typeof response.data!== 'undefined' && response.data.success==200){  
          this.privacy_data =response.data.im_agreement;
          // $('#htmld').html(response.data.privacy);
        }
      }).catch(e => {
          console.log(e)
      });
    } 

    created() {      
      document.title = 'Platform Agreement || Caprock Merchant Platform';  
    }
     
  } 
