
    import { Options, Vue } from 'vue-class-component'; 
    import Basic from '@/components/customer/Basic.vue';
    import Rightside from '@/components/customer/Rightside.vue'; 
    import Common from '@/services/common.js';
    import moment from 'moment';
    import $ from 'jquery';  
    import VueApexCharts from "vue3-apexcharts";

    import Portfolio_card from '@/components/accounts/Portfolio_card.vue';
 
 
  @Options({
    components: { 
        'right-side':Rightside,
        'apexchart': VueApexCharts,
        'portfolio-card': Portfolio_card,
        Basic 
    },
  })
  export default class Paymaster extends Vue { 
    STORAGE_KEY = "caprock-local";   
    user_name = ''; 
    user_info = {}; 
    customer_accounts       =   []; 
    total_ac_balance        =   0;  
    total_active_default_balance    =   0;  
    total_archive_default_balance   =   0;  
    default_currency_icon           =   '$';  
    default_currency                =   'AUD';  
    subac :any =  [];
    transactions :any =  [];
    all_transactions :any =  [];
    active_subac :any = '';
    local_storage:any;
    crypto = {
        ac_balance                  :0,
        default_currency_balance    :0,
        userac_name                 :'Digital Assets',
        userac_reference_code       :'#',
        userac_establish_date       :''
    }; 

    

    timer :any= undefined;
    
    /*Chart Var*/
        last_12_months:any = [
            moment().subtract(11, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(10, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(9, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(8, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(7, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(6, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(5, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(4, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(3, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(2, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(1, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(0, "month").startOf("month").format('MMM, YYYY'), 
        ];  
        active_chart=1;
        on_chart:any=0;
        chart1:any = [0,0,0,0]
        chart2:any = [0,0,0]
        chart3:any = [0,0,0,0,0,0]
        chart4:any = [0,0,0,0,0,0,0,0,0,0,0,0]
        chart5:any = [0,0]
        chartOptions1= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#7957f5',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: ["Week 1", "Week 2", "Week 3", "Week 4"],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#7957f5'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series1 = [
            {
                name: "Balance",
                data: this.chart1
            },
        ];
 
        chartOptions2= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#7957f5',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: [
                    moment().subtract(2, "month").startOf("month").format('MMM, YYYY'), 
                    moment().subtract(1, "month").startOf("month").format('MMM, YYYY'), 
                    moment().subtract(0, "month").startOf("month").format('MMM, YYYY'), 
                ],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#7957f5'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series2 = [
            {
                name: "Balance",
                data: this.chart2
            },
        ];

        chartOptions3= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#7957f5',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: [
                    moment().subtract(5, "month").startOf("month").format('MMM, YYYY'),  
                    moment().subtract(4, "month").startOf("month").format('MMM, YYYY'), 
                    moment().subtract(3, "month").startOf("month").format('MMM, YYYY'), 
                    moment().subtract(2, "month").startOf("month").format('MMM, YYYY'), 
                    moment().subtract(1, "month").startOf("month").format('MMM, YYYY'), 
                    moment().subtract(0, "month").startOf("month").format('MMM, YYYY'), 
                ],
                 
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#7957f5'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series3 = [
            {
                name: "Balance",
                data: this.chart3
            },
        ];


        chartOptions4= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#7957f5',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories:this.last_12_months
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#7957f5'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series4 = [
            {
                name: "Balance",
                data: this.chart4
            },
        ];

        chartOptions5= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#7957f5',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: [
                    "0000", "0000"
                ],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#7957f5'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series5 = [
            {
                name: "Balance",
                data: this.chart4
            },
        ];

    /*Chart End*/
        
    
    created() {     
        var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
        if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
            /*console.log('Digital Assets');*/
        }else{ 
            localStorage.removeItem(this.STORAGE_KEY); 
            this.$router.push('/sign-in'); 
        } 
        document.title = "Digital Assets || Caprock Merchant Platform";
        localStorage.setItem(
            'active_page', 'crypto'
        ); 
    }
    mounted(){
        this.user_name=''; 
        this.get_user_accounts(); 
        this.get_user_info(); 
        this.get_account_detail('crypto');  
    }
    open_link(id){
        var link = (document.getElementById("URL"+id) as HTMLInputElement).value; 
        window.open(link,'_blank');
    }

    get_user_accounts() { 
        Common.get_user_accounts(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                    this.total_ac_balance   =   response.data.t_balance.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) ;   
                    this.total_active_default_balance=0;
                    this.total_archive_default_balance=0;
                    var ac_data = response.data.accounts; 
                    for (let i = 0; i < ac_data.length; i++) { 
                        /*ac_data[i]['ac_percent']=(100*ac_data[i]['ac_balance']/response.data.accounts.at(-1).t_balance);*/

                        if(ac_data[i]['userac_status']==1){ 
                            this.total_active_default_balance= this.total_active_default_balance+ ac_data[i]['default_currency_balance'];   
                        }else{
                            this.total_archive_default_balance= this.total_archive_default_balance+ ac_data[i]['default_currency_balance'];   
                        }
                    } 
                    for (let i = 0; i < ac_data.length; i++) {  
                        ac_data[i]['ac_percent']=(100*ac_data[i]['default_currency_balance']/this.total_active_default_balance); 
                    } 
                    this.customer_accounts=ac_data;
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_user_info() { 
        Common.get_user_info(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    var user_inf=response.data.profile; 
                    user_inf.join_date=user_inf.join_date?moment(user_inf.join_date).format('DD MMM, YYYY YYYY '):'';
                    this.user_info=user_inf; 

                    this.default_currency       =   user_inf.default_currency;
                    this.default_currency_icon  =   user_inf.default_currency_icon;
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_account_detail(ac_name) {   
        Common.get_account_detail(this.local_storage['access-token'],ac_name)
            .then(response => {
                this.subac=[]; 
                if (typeof response.data !== 'undefined' && response.data.success == 200) {   
                    var cryptodata = response.data.ac_info; 

                    cryptodata.userac_establish_date     =   moment(cryptodata.userac_establish_date).format('DD MMM, YYYY ');
                    this.crypto                          =   cryptodata;
                    
                    this.subac = response.data.ac_data.subaccounts; 
                     


                    var subII:any = localStorage.getItem('crypto_subac_id'); 
                    var subId =''; 
                    if(typeof subII!=='undefined' && subII>0){
                        subId =subII;
                    }else{
                        subId = response.data.ac_data.subaccounts.at(-1).subac_id;
                    }
                    this.active_subac=subId;
                    this.get_crypto_transactions(this.active_subac)


                    /*Chart Data*/
                        var chart_info =response.data.chart_data;  
                        this.chart1 = chart_info.last_month_chart;  
                        var new_series1 = [
                            {
                                name: "Balance",
                                data: this.chart1,
                            },
                        ]; 
                        this.series1=new_series1  

                        this.chart2 = chart_info.three_month_chart;
                        var new_series2 = [
                            {
                                name: "Balance",
                                data: this.chart2,
                            },
                        ]; 
                        this.series2=new_series2 

                        this.chart3 = chart_info['six_month_chart'];
                        var new_series3 = [
                            {
                                name: "Balance",
                                data: this.chart3,
                            },
                        ]; 
                        this.series3=new_series3  

                        this.chart4 = chart_info['year_chart'].reverse();
                        var new_series4 = [
                            {
                                name: "Balance",
                                data: this.chart4,
                            },
                        ]; 
                        this.series4=new_series4 
 
                         
                        this.chart5 = [];
                        var labelss :any= [];
                        if(chart_info['all_chart'].length==1){
                            this.chart5.push(0);
                            labelss.push((chart_info['all_chart'][0]['year']-1));
                        }
                        for (let i = 0; i < chart_info['all_chart'].length; i++) {  
                            this.chart5.push(chart_info['all_chart'][i]['balance']);
                            labelss.push(chart_info['all_chart'][i]['year']);
                        }
                        var newchartOptions5= {
                            chart: {
                                id: "vuechart-example",
                                type: "line",
                                height: 180,
                                foreColor: '#7957f5',
                                toolbar: {
                                    show: false
                                },
                                stacked:true
                            },
                            xaxis: {
                                categories: labelss, 
                            }, 
                            yaxis: { 
                                axisBorder: {
                                    show: false
                                },
                                axisTicks: {
                                    show: false,
                                }, 
                                labels: {
                                    show: false,
                                    formatter: function (val) {
                                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                                    }
                                },
                                min: 0,
                                forceNiceScale: true,

                            },
                            plotOptions: {
                                bar: {
                                    borderRadius: 4,
                                    columnWidth: '35%',
                                    dataLabels: {
                                        position: 'top', 
                                    },
                                }
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                    return "$" + val;
                                },
                                offsetY: -20,
                                style: {
                                    fontSize: '12px',
                                    colors: ['#FFF']
                                }
                            }, 
                            colors: ['#7957f5'], 
                            grid: {
                                show: false,
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },  
                            }, 
                        }; 
 
                        var new_series5 = [
                            {
                                name: "Balance",
                                data: this.chart5,
                            },
                        ]; 
                        this.chartOptions5=newchartOptions5; 
                        this.series5=new_series5;
                        this.on_chart=1 ;
                    /*Chart End*/
                        

                } 
            }).catch(e => {
              console.log(e)
        });
    }

    filterTrans(event:any)
        {      
            var subac_id = event.target.value;
            if(typeof subac_id != "undefined" && subac_id>0){
                localStorage.setItem(
                    'crypto_subac_id', subac_id
                ); 
                this.active_subac = subac_id
                this.get_crypto_transactions(this.active_subac); 
            }
        }
    
        get_crypto_transactions(subac_id){ 
        if(typeof subac_id != "undefined" && subac_id>0){
            localStorage.setItem(
                'crypto_fee_subac_id', subac_id
            ); 
            this.active_subac = subac_id

            Common.get_crypto_transactions(this.local_storage['access-token'],subac_id)
                .then(response => {
                    this.transactions    =[]; 
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {   
                        var transactions = response.data.transactions;   
                        for (let i = 0; i < transactions.length; i++) { 
                            transactions[i]['t_time']=moment(transactions[i]['trans_date']).format('DD MMM, YYYY'); 
                        }  
                        this.transactions=transactions;  
                    } 
                }).catch(e => {
                console.log(e)
            }); 
        }   
    }
 
    data () {        
        return {  
            customer_accounts:this.customer_accounts,
            user_info:this.user_info,
            default_currency_icon:this.default_currency_icon,
            total_active_default_balance:this.total_active_default_balance,
        } 
    }   

  }
