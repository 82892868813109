
  import { Options, Vue } from 'vue-class-component';   
  import Common from '@/services/common.js';  
  import $ from 'jquery';  
  import 'bootstrap';

  // var verificationCode:any = [];
  // $(".verification-code input[type=text]").keyup(function (event) {
      
  //     $(".verification-code input[type=text]").each(function (i) {
  //         verificationCode[i] = $(".verification-code input[type=text]")[i].value;
  //         $('#verificationCode').val(Number(verificationCode.join('')));
  //     });

  //     if ($(this).val().length > 0) {
  //         if (event.key == 1 || event.key == 2 || event.key == 3 || event.key == 4 || event.key == 5 || event.key == 6 || event.key == 7 || event.key == 8 || event.key == 9 || event.key == 0) {
  //             $(this).next().focus();
  //         }
  //     } else {
  //         if (event.key == 'Backspace') {
  //             $(this).prev().focus();
  //         }
  //     }
  // });


  // import useVuelidate from '@vuelidate/core';
  // import { required, minLength, email, sameAs,between } from '@vuelidate/validators';

  import Axios from "axios";
  @Options({
    components: {  
    },
    /*validations: {
      name: {
        required,
        minLength: minLength(4)
      },
      email: {
        between: between(20, 30)
      }
    }*/
  })

  export default class VerifySecurity extends Vue {
    errors : string[]=[];
    err : any[]=[];
    form      ={};
    STORAGE_KEY = "caprock-local";  
    session = JSON.parse(localStorage.getItem(this.STORAGE_KEY) || "{}");
    data () {    
      Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';   
       
      return {  
        form: {
          user_email        : '',
          security_token    : ''
        }  
      }
    }   
 
    created() {     
      var js =JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));   
      if(typeof js!=='undefined' && typeof js['access-token']!=='undefined' && js['access-token']!=''){
        this.$router.push('/home'); 
      } 
      Common.get_device_info()
      .then(response => { 
       /* if(typeof response.data!== 'undefined' && response.data.success==1){
          this.countries =   response.data.data.countries ;   
        }*/
        console.log(response);
      }).catch(e => {
          console.log(e)
      });    
      document.title = 'Verify Security || Caprock Merchant Platform';   
     
      return true;
    }
    
     
    submitForm(e:any){ 

      $('#subBtn').attr('disabled','disabled');
      $('#subBtn').text('Processing ... ');
      var js =JSON.parse(JSON.stringify((this.form))); 
      
      /*Validation Check*/  
        this.err   = []; 
        var required = [
          {'key':'user_email','name':'Email'}  
        ];
        
        var valid_emails = [ 
          {'key':'user_email','name':'Email'}  
        ];  

   
        var new_err:any = [];
        required.map(function(value, key) { 
          if (!js[value.key]) {
            var  err_msg = value.name+' is required, please fill it &#9888'; 
            new_err.push({'key':key,'value':value,'msg':err_msg});
          } 
        });   
        this.err = new_err; 
        if (!this.err.length) {
          valid_emails.map(function(value, key) { 
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; 
            if (!re.test(String(js[value.key]).toLowerCase())) { 
              var  err_msg = 'Please enter a valid email address for field - '+value.name+' &#9888'; 
              new_err.push({'key':key,'value':value,'msg':err_msg});
            }  
          });    
          this.err = new_err;
        }

      if (!this.err.length) {
        let form_data = new FormData(); 
        form_data.append('device_id', 'MYDEVICEID'); 
        form_data.append('device', 'DEVICE');   
        for (var key in js ) {
          form_data.append(key, js[key]);
        }  
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        var url ='https://api.caprock.com.au/password-verification-code'; 
        // var url ='http://127.0.0.1:8000/password-verification-code'; 
        Axios.post(url, 
          form_data, 
          {
            headers: {  
              'Content-Type': 'multipart/form-data'
            }, 
            onUploadProgress: function( progressEvent:any ) {
              var loaded:number  =  progressEvent.loaded;
              var total:number   =  progressEvent.total;
              var per   =    parseInt((loaded/total *100).toFixed()) ;  
            }.bind(this)
          }
        )
        .then(response => { 
          if(response.data.success==200){
            alert(response.data.message); 
            localStorage.setItem(this.STORAGE_KEY, JSON.stringify({'access-token':response.data.user_token}));
            // this.session = JSON.parse(localStorage.getItem(this.STORAGE_KEY) || "{}");   
            this.$router.push('/sign-in');
          }else{
            alert(response.data.message);
            $('#subBtn').removeAttr('disabled');
            $('#subBtn').text('Apply Now');
          } 
        })
        .catch(error => {
          $('#subBtn').removeAttr('disabled');
          $('#subBtn').text('Apply Now'); 
        }); 
      }else{
        // console.log(this.err);
        /*for (var key in this.errors ) {
          form_data.append(key, js[key]);
        } */

        var errs:any =JSON.parse(JSON.stringify((this.err)));
        // console.log(errs);
        errs.map(function(v:any, k:any) { 
          var error_msg =  v.msg;  
          $("#"+v.value.key).before('<span class="'+v.value.key+'err danger ml-2 float-right">'+error_msg+'</span>'); 
        });  
        document.body.scrollTop = 0;  
        document.documentElement.scrollTop = 0;   
        // this.openModal('exampleModal');
        e.preventDefault();  
        $('#subBtn').removeAttr('disabled');
        $('#subBtn').text('Apply Now'); 
      }   
    } 

    remove_err(event:any){ 
      $('.'+event.currentTarget.id+'err').remove();
    }

    // Code OTP 
      

    

     

    openModal(id:any){
      $('#'+id).addClass('d-block');
      $('#'+id).addClass('show');
      $('#'+id).css('overflow-y','scroll');
    }
    closeModal(id:any){
      $('#'+id).removeClass('d-block');
      $('#'+id).addClass('d-none');
      $('#'+id).removeClass('show'); 
    }
 
      
  } 
