
  import { Options, Vue } from 'vue-class-component'; 
  @Options({  
    props: {
      load_on: String, 
    },
  })
  export default class Loader extends Vue { 
     
  }
