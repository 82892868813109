
  import { Options, Vue } from 'vue-class-component';   
  import Common from '@/services/common.js';  
  import App from './App.vue';  
  import $ from 'jquery';  
  import 'bootstrap'; 
  import Basic from '@/components/Basic.vue';  

  // import useVuelidate from '@vuelidate/core';
  // import { required, minLength, email, sameAs,between } from '@vuelidate/validators';

  import Axios from "axios";
  @Options({
    components: {  
      Basic
    }, 
  })

  export default class Privacy extends Vue { 
    privacy ={}; 
    STORAGE_KEY = "caprock-local";   
    data () {    
      Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
      this.get_privacys(); 
       
      return {
        privacy:this.privacy  
      }
    }   
  
    get_privacys(){ 
      Common.get_privacy()
      .then(response => { 
        if(typeof response.data!== 'undefined' && response.data.success==200){ 
          console.log()
          $('#htmld').html(response.data.privacy);
        }
      }).catch(e => {
          console.log(e)
      });
    } 

    created() {      
      document.title = 'Privacy Policy || Caprock Merchant Platform';  
    }
     
  } 
