
    import { Options, Vue } from 'vue-class-component';
    import HelloWorld from '@/components/HelloWorld.vue';
    import Basic from '@/components/customer/Basic.vue';
    import Rightside from '@/components/customer/Rightside.vue'; 
    import Common from '@/services/common.js';
    import moment from 'moment';
    import $ from 'jquery'; 
    import bootstrap from 'bootstrap';  
 
    @Options({
        components: {
            HelloWorld, 
            'right-side':Rightside,
            Basic 
        },
    })

    export default class Profile extends Vue { 
        STORAGE_KEY = "caprock-local";   
        user_name = ''; 
        user_info = {}; 
        customer_accounts   =   []; 
        total_ac_balance    =   0;  
        local_storage:any;
        dashboard = {
            currencies:[]
        };
        alerts:any = [];
        pwd_label= 'Update Now';


        new_password = '';
        confirm_password = '';
        profile_url = localStorage.getItem('profile_url');
        b_profile_url ='https://admin.caprock.com.au/inlancer-admin/images/user.png'; 
        erorr = '';
        success = '';


        d_currency      ='AUD';
        d_currency_icon ='$';
        portfolio   =0;
        accounts    =0;
        deposit     =0;
        withdrawal  =0; 
        switch  :any   = '';
        

        data () {      
            return {  
                customer_accounts   :this.customer_accounts,
                user_info           :this.user_info   
            }  
        }  

         
         

        created() {    
            
            var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
            if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
                // /*console.log('Try After Sometime');*/
            }else{ 
                localStorage.removeItem(this.STORAGE_KEY); 
                this.$router.push('/sign-in'); 
            } 
            document.title = "My Profile || Caprock Merchant Platform";
            localStorage.setItem(
                'active_page', 'profile'
            ); 



            var q_params = this.$route.query;
            if(q_params['switch']=='business'){
                /*SWITCH TO MY BUSINESS AC IN DIRECTOR*/ 
                Common.switch_b_account(this.local_storage['access-token'],2)
                .then(response => { 
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        localStorage.setItem('active_profile','business'); 
                    }
                }).catch(e => {
                    console.log(e)
                });
            } 
            if(q_params['switch']=='my'){
                /*SWITCH TO IDDIVIDUAL*/
                Common.switch_b_account(this.local_storage['access-token'],1)
                .then(response => { 
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        localStorage.setItem('active_profile','my'); 
                    }
                }).catch(e => {
                    console.log(e)
                });
            } 

        }
        mounted(){
            this.user_name=''; 
            this.get_user_info(); 
            this.get_user_accounts(); 
            this.get_user_portfolio(); 
        }
        loadImage(){
          this.profile_url = this.b_profile_url;
        }

        get_user_accounts() { 
            Common.get_user_accounts(this.local_storage['access-token'])
                .then(response => {

                     
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        
                        this.customer_accounts  =   response.data.accounts;
                        this.total_ac_balance   =   response.data.t_balance.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) ; 
 
                        var all_acs    =   this.customer_accounts;
                        var ac_count   =   this.customer_accounts.length;
                        var credit=0;
                        var debit=0;
                        var balance=0;
                        var d_balance=0;
                        for (let i = 0; i < all_acs.length; i++) { 
                            credit=credit+all_acs[i]['userac_credit'];
                            debit=debit+all_acs[i]['userac_debit'];
                            balance=balance+all_acs[i]['userac_balance'];
                            d_balance=d_balance+all_acs[i]['default_currency_balance'];

                            this.d_currency      =  all_acs[i]['default_currency'];
                            this.d_currency_icon =  all_acs[i]['default_currency_icon'];
                        } 
                        this.portfolio=d_balance; 
                        this.accounts   =ac_count; 
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        get_user_info() { 
            Common.get_user_info(this.local_storage['access-token'])
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        var user_inf        =   response.data.profile; 
                        user_inf.join_date  =   user_inf.join_date?moment(user_inf.join_date).format('DD MMM YYYY '):'';
                        this.user_info      =   user_inf;  
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        get_user_portfolio() { 
            Common.get_user_portfolio(this.local_storage['access-token'])
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        var portfolio=response.data.portfolio; 
                        this.deposit    =portfolio['deposit'];
                        this.withdrawal =portfolio['withdraw'];   
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        change_password() {  
            if(typeof this.new_password==='undefined' ||  this.new_password==''){
                this.erorr= "Please enter your new password";
            }
            else if(typeof this.confirm_password==='undefined' ||  this.confirm_password==''){
                this.erorr= "Please enter confirm password";
            }
            else if(this.new_password!=this.confirm_password){
                this.erorr= "Confirm password didn't matched";
            }else{
                this.pwd_label = 'Processing...';
                Common.change_password(this.local_storage['access-token'],this.new_password,this.confirm_password)
                    .then(response => {
                        if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                             
                            this.pwd_label='Password changed !';
                            this.success='Password changed !' 
                            setTimeout(function(){location.reload();},1000);
                        }else{
                            this.pwd_label='Update Password';
                            this.erorr=response.data.message ;
                        }
                    }).catch(e => {
                      console.log(e)
                });
            } 
            
        }
        get_dashboard() { 
            Common.get_dashboard(this.local_storage['access-token'])
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        this.dashboard=response.data.dashboard; 
                    }
                }).catch(e => {
                  console.log(e)
            });
        } 
        
        openModal(id: any) {
            $('#' + id).addClass('d-block');
            $('#' + id).addClass('show');
            $('#' + id).css('overflow-y', 'scroll');
        }
        closeModal(id: any) {
            $('#' + id).removeClass('d-block');
            $('#' + id).addClass('d-none');
            $('#' + id).removeClass('show');
        }  
  }
