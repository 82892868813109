
  import { Options, Vue } from 'vue-class-component';
   
  @Options({
    props: {
        active_tab:		String,
        myinv_id: 		Number, 
    }
  })
  export default class MyinvHeader extends Vue {
    active_tab  
    myinv_id 
    data () {     
      
      return {  
        active_tab:this.active_tab,
        myinv_id:this.myinv_id, 
      }
 
    }    
    created() {       
      return true;
    }
    mounted() {
        this.myinv_id 
        this.active_tab    
    }  
  }
