
    import { Options, Vue } from 'vue-class-component';
    import {ref } from 'vue';
    import Common from '@/services/common.js';
    import moment from 'moment';  
    import { useToast } from "vue-toastification";
    import Axios from "axios";     
    import { Form, Field,ErrorMessage ,useForm} from 'vee-validate';
    import $ from 'jquery'; 

    @Options({ 
        components: { 
            Form,
            Field,
            useForm,
            ErrorMessage
        },
        props: {
            countries     : [Number, String],
            current_step:     Number,  
            load_on:     Number,  
        },  
    })
    
    export default class Banks extends Vue {  
        current_step;  
        show_form=0;
        countries; 
        load_on;
        form = {}; 
        bank_accounts       :any        =   [];
        toast:any = useToast();
        local_storage       :any;  
        data () {     
           
            return {      
                countries       :   this.countries,  
                show_form       :   this.show_form,  
                current_step    :   this.current_step,  
                load_on         :   this.load_on,  
                bank_accounts   :   this.bank_accounts, 
            } 
        }    
        created() {    
            this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));     
            return true;
        }
        mounted() { 
            this.bank_list();  
        }  

        openDoc(path){ 
            window.open(path,'_blank');
        } 
        uploadFile(event){ 
            var path_item       = event.target.name+'_path';
            var item_url        = event.target.name+'_url';
            var pbar            = event.target.name+'_pbar';
            var pbarp           = event.target.name+'_pbar_p'; 
            var pdiv            = event.target.name+'_pdiv';

            this.form[pdiv]     =   1;   
            this.form[pbar]     =   0;   
            this.form[pbarp]    =  '0%';    

            
            var file        = event.target.files[0];   
            let form_data   = new FormData();  
            form_data.append('user_token',this.local_storage['access-token']);

            if(typeof file !='undefined' && file!=null){
                form_data.append('file',file); 

                Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                var url =Common.base_url()+'upload-file';    
                Axios.post(
                    url, 
                    form_data, 
                    {
                        headers: {  
                            'Content-Type': 'multipart/form-data'
                        }, 
                        onUploadProgress: progressEvent => {
                            var loaded:number   =   progressEvent.loaded; 
                            var total:number    =   progressEvent.total;
                            var per             =   parseInt((loaded/total *100).toFixed());   
                            this.form[pbar]     =   per; 
                            this.form[pbarp]    =   per+'%';  

                            if(per==100){
                                this.form[pbarp]    =   'Processing';  
                            } 
                        }, 
                    }
                )
                .then(response => { 
                    if(response.data.success==200){  
                        this.form[pbarp]        =   'Upload Success';  
                        this.form[path_item]    =   response.data.file_path;     
                        this.form[item_url]     =   response.data.file_url;    
                    }else{
                        this.form[pdiv] =   0;   
                        this.form[pbar] =   0;
                        this.form[pbarp]=   '0%';
                         
                        this.toast.info(response.data.message);  
                    } 
                })
                .catch(error => {
                    this.form[pdiv] =   0;   
                    this.form[pbar] =   0;
                    this.form[pbarp]=   'Failed to upload';   
                    this.toast.error('Failed to upload');   
                    return false;
                });  

            }else{ 
                this.toast.info('Please select file first');   
            }
     
        }
        validateEmail(value) { 
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (typeof value!='undefined' && !regex.test(value)) { 
                return 'This field must be a valid email'; 
            } 
            return true;
        }  
        requiredField(value) { 
            if (!value) {
                return 'This field is required';
            } 
            return true;
        }
        selectField(value) { 
            if (!value || value=='') {
                return 'Please select one of option';
            } 
            return true;
        } 
        mobileField(value){ 
            if(typeof value!='undefined'){
                var phoneNum = value.replace(/[^\d]/g, '');
                if(phoneNum.length <7 || phoneNum.length > 12) {  
                    return 'This field must be a valid mobile number';
                }  
                return true;
            }
            return true; 
        } 
     


        async bank_list(){
            await Common.get_bank_accounts(this.local_storage['access-token'])
                .then(response => { 
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        var bank_accounts   =   response.data.bank_accounts; 
                        this.bank_accounts  =   bank_accounts;   
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        /*reset_bank_form(){
            this.$nextTick(() => { 
                var tBox :any= this.$refs.bank_form;
                tBox.resetForm(); 
            }); 
        }*/

        save_bank(){  
              

            setTimeout(() => this.load_on=1, 100); 
            var form_records  = JSON.parse(JSON.stringify((this.form)));   
            let form_data = new FormData();  
            form_data.append('user_token'           ,this.local_storage['access-token']);              
            
            form_data.append('bank_name',               form_records['bank_name']);
            form_data.append('bank_swift_code',         form_records['bank_swift_code']);
            form_data.append('bank_account_no',         form_records['bank_account_no']);
            form_data.append('bank_currency_id',        form_records['bank_currency_id']);
            form_data.append('bank_verification_doc',   form_records['bank_verification_doc_path']);
            form_data.append('bank_address',            form_records['bank_address']);
            form_data.append('bank_bsb',                form_records['bank_bsb']); 
              
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] =  '*';
            var url     =   Common.base_url()+'save-external-bank-account';   
            Axios.post(
                url, 
                form_data, 
                {
                    headers: {  
                        'Content-Type': 'multipart/form-data'
                    }, 
                    onUploadProgress: function( progressEvent:any ) {
                        var loaded:number   =   progressEvent.loaded;
                        var total:number    =   progressEvent.total;
                        var per             =   parseInt((loaded/total *100).toFixed());  
                    }.bind(this)
                }
            )
            .then(response => { 
                if(response.data.success==200){
                    this.bank_list(); 

                    /*this.form['bank_name']='';
                    this.form['bank_swift_code']=''; 
                    this.form['bank_account_no']='';
                    this.form['bank_currency_id']='';
                    this.form['bank_verification_doc_path']='';
                    this.form['bank_address']='';
                    this.form['bank_bsb']=''; */
                    
                    this.form['bank_verification_doc_pdiv']=0;
                    this.form['bank_verification_doc']='';

                    this.$nextTick(() => { 
                        var tBox :any= this.$refs.bank_form;
                        tBox.resetForm(); 
                    }); 

                    setTimeout(() => this.show_form =0, 800);  
                    
                    
                    /*if(document.getElementById('bank_form')){
                        window.document.getElementById('bank_form').reset();
                    } */
                     

                    /*($('#bank_form') as any).reset();*/

                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.success(response.data.message);   
                }else{ 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.error(response.data.message);   
                } 
            })
            .catch(error => {
                setTimeout(() => this.load_on=0, 500);   
            });   
        } 
        delete_bank(bank_id){ 
            setTimeout(() => this.load_on=1, 100); 
            var form_records  = JSON.parse(JSON.stringify((this.form)));   
            let form_data = new FormData();  
            form_data.append('user_token'           ,this.local_storage['access-token']);              
            form_data.append('bank_id'          ,bank_id); 
              
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] =  '*';
            var url     =   Common.base_url()+'delete-bank-account';   
            Axios.post(
                url, 
                form_data, 
                {
                    headers: {  
                        'Content-Type': 'multipart/form-data'
                    }, 
                    onUploadProgress: function( progressEvent:any ) {
                        var loaded:number   =   progressEvent.loaded;
                        var total:number    =   progressEvent.total;
                        var per             =   parseInt((loaded/total *100).toFixed());  
                    }.bind(this)
                }
            )
            .then(response => { 
                if(response.data.success==200){
                    this.bank_list(); 
                    this.$nextTick(() => { 
                        var tBox :any= this.$refs.bank_form;
                        tBox.resetForm(); 
                    }); 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.success(response.data.message);   
                }else{ 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.error(response.data.message);   
                } 
            })
            .catch(error => {
                setTimeout(() => this.load_on=0, 500);   
            });   
        } 

    }
