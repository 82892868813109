
    import { Options, Vue } from 'vue-class-component';   
  
    @Options({
        props: { 
            customer_accounts: [Number, String],
            user_info: Object
        }
    })
    export default class Rightside extends Vue {   
        system_version      = localStorage.getItem('system_version');
        profile_url         = localStorage.getItem('profile_url');
        b_profile_url       ='https://admin.caprock.com.au/inlancer-admin/images/user.png';  
        active_page         = localStorage.getItem('active_page');   

        user_info            
        customer_accounts   
        data () {       
            return {   
                customer_accounts   :   this.customer_accounts,
                user_info           :   this.user_info, 
                system_version      :   this.system_version
            } 
        }      
        mounted(){
          document.body.classList.add('overflow-y-scroll')
        }
        loadImage(){
          this.profile_url = this.b_profile_url;
        } 


        /*get_user_accounts() { 
            Common.get_user_accounts(this.token)
                .then(response => { 
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        var ac_data = response.data.accounts; 
                        for (let i = 0; i < ac_data.length; i++) { 
                            ac_data[i]['ac_percent']=(100*ac_data[i]['ac_balance']/response.data.accounts.at(-1).t_balance);
                        }  
                        this.customer_accounts=ac_data;
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        get_user_info() { 
            Common.get_user_info(this.token)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        var user_inf=response.data.profile; 
                        user_inf.join_date=user_inf.join_date?moment(user_inf.join_date).format('DD MMM YYYY '):'';
                        this.user_info=user_inf; 
                    }
                }).catch(e => {
                  console.log(e)
            });
        }*/
        

    }
