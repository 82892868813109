
    import { Options, Vue } from 'vue-class-component';
    import {ref } from 'vue';
    import Common from '@/services/common.js';
    import moment from 'moment';  
    import { useToast } from "vue-toastification";
    import Axios from "axios";     
    import { Form, Field,ErrorMessage ,useForm} from 'vee-validate';
    import $ from 'jquery'; 

    @Options({ 
        components: { 
            Form,
            Field,
            useForm,
            ErrorMessage
        },
        props: {
            countries     : [Number, String],
            current_step:     Number,  
            load_on:     Number,  
        },  
    })
    
    export default class Trustees extends Vue {  
        current_step;  
        show_Tdirectors:any=0;
        show_form=0;
        show_dir_form = 0;
        countries; 
        load_on;
        active_trustee_id :any =0;
        active_director_id :any =0;
        form = {}; 
        trustees       :any        =   [];
        
        toast:any                   = useToast();
        local_storage   :any;
        closeImage = 'caprock/images/close.svg';  
        data () {     
           
            return {      
                countries       :   this.countries,  
                active_trustee_id:this.active_trustee_id,
                active_director_id:this.active_director_id,
                show_form       :   this.show_form,
                show_dir_form   :   this.show_dir_form,  
                current_step    :   this.current_step,  
                load_on         :   this.load_on,  
                trustees        :   this.trustees, 
                closeImage      :   this.closeImage, 
                show_Tdirectors : this.show_Tdirectors,
            } 
        }    
        created() {    
            this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));     
            return true;
        }
        mounted() { 
            this.trustee_list();  
        }  
 
        
        validateEmail(value) { 
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (typeof value!='undefined' && !regex.test(value)) { 
                return 'This field must be a valid email'; 
            } 
            return true;
        }  
        requiredField(value) { 
            if (!value) {
                return 'This field is required';
            } 
            return true;
        }
        selectField(value) { 
            if (!value || value=='') {
                return 'Please select one of option';
            } 
            return true;
        } 
        mobileField(value){ 
            if(typeof value!='undefined'){
                var phoneNum = value.replace(/[^\d]/g, '');
                if(phoneNum.length <7 || phoneNum.length > 12) {  
                    return 'This field must be a valid mobile number';
                }  
                return true;
            }
            return true; 
        } 
        async trustee_list(){
            await Common.get_trustees(this.local_storage['access-token'])
                .then(response => { 
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        var trustees   =   response.data.trustees;
                        for (var i = trustees.length - 1; i >= 0; i--) {
                            if(trustees[i].trustee_type==1){
                                trustees[i].trustee_type='Indvidual';
                            }
                            if(trustees[i].trustee_type==2){
                                trustees[i].trustee_type='Corporate';
                            }

                            trustees[i].directors=[];
                        } 
                        this.trustees  =   trustees;    
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        

        async director_list(trustee_id){
            await Common.get_trustee_directors(this.local_storage['access-token'],trustee_id)
            .then(response => { 
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    var trustees   =   this.trustees;
                    for (var i = trustees.length - 1; i >= 0; i--) {
                        if (trustees[i].trustee_id==trustee_id) {
                            trustees[i].directors=response.data.directors;
                        }
                    } 
                    this.trustees  =   trustees;     
                }
            }).catch(e => {
                console.log(e)
            });
        }

        add_director(trustee_id){
            alert('ADD_'+trustee_id);
        } 
        async show_directors(trustee_id){
            this.active_trustee_id=trustee_id   
            await this.director_list(trustee_id);
            this.show_Tdirectors =trustee_id;   
        }
        htmlToElements(html) {
            var template = document.createElement('template');
            html = html.trim(); // Never return a text node of whitespace as the result
            template.innerHTML = html;
            return template.content.firstChild;
        }
        getImgUrl(path) { 
            return require('@/assets/' + path);
        }


        hide_directors(dir_id){ 
            this.show_Tdirectors =0;  
        }

 

        save_trustee(){    
            setTimeout(() => this.load_on=1, 100); 
            var form_records  = JSON.parse(JSON.stringify((this.form)));   
            let form_data = new FormData();  
            form_data.append('user_token'           ,this.local_storage['access-token']);              
                
            var sT11 = [
                'trustee_type',
                'company_type',
                'trustee_name',
                'trustee_email',
                'trustee_phone_no' 
             ]; 
            for (var sT = 0; sT < sT11.length; sT++) {   
                if(typeof form_records[sT11[sT]]!=='undefined' && form_records[sT11[sT]]!='undefined'){
                    form_data.append(sT11[sT],form_records[sT11[sT]]);    
                }else{
                    form_data.append(sT11[sT],'');    
                } 
            }  
  
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] =  '*';
            var url     =   Common.base_url()+'save-onboard-trustee';   
            Axios.post(
                url, 
                form_data, 
                {
                    headers: {  
                        'Content-Type': 'multipart/form-data'
                    } 
                }
            )
            .then(response => { 
                this.trustee_list();   
                this.$emit('recipientFetch');
                if(response.data.success==200){
                    this.$nextTick(() => { 
                        var tBox :any= this.$refs.trustee_form;
                        tBox.resetForm(); 
                    });  
                    setTimeout(() => this.show_form =0, 800);   
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.success(response.data.message);   
                }else{ 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.error(response.data.message);   
                } 
            })
            .catch(error => {
                setTimeout(() => this.load_on=0, 500);   
            });   
        } 

        ask_delete(trustee_id){
            this.active_trustee_id = trustee_id;
            this.openModal('delete_trustee');
        }
        delete_trustee(trustee_id){ 
            setTimeout(() => this.load_on=1, 100); 
            var form_records  = JSON.parse(JSON.stringify((this.form)));   
            let form_data = new FormData();  
            form_data.append('user_token'           ,this.local_storage['access-token']);              
            form_data.append('trustee_id'          ,trustee_id); 
              
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] =  '*';
            var url     =   Common.base_url()+'delete-onboard-trustee';   
            Axios.post(
                url, 
                form_data, 
                {
                    headers: {  
                        'Content-Type': 'multipart/form-data'
                    } 
                }
            )
            .then(response => { 
                this.active_trustee_id=0 ;
                this.closeModal('delete_trustee');
                this.trustee_list();  

                this.$emit('recipientFetch');
                if(response.data.success==200){  
                    this.$nextTick(() => { 
                        var tBox :any= this.$refs.trustee_form;
                        tBox.resetForm(); 
                    }); 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.success(response.data.message); 
                }else{ 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.error(response.data.message);   
                } 
            })
            .catch(error => {
                setTimeout(() => this.load_on=0, 500);   
            });   
        } 



        save_director(){       
            setTimeout(() => this.load_on=1, 100); 
            var form_records  = JSON.parse(JSON.stringify((this.form)));   
            let form_data = new FormData();  
            form_data.append('user_token'           ,this.local_storage['access-token']);              
             
            form_data.append('trustee_id'           ,this.active_trustee_id);
            form_data.append('director_name'        ,form_records['director_name']);
            form_data.append('director_email'       ,form_records['director_email']);
            form_data.append('director_phone_no'    ,form_records['director_phone_no']);
              
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] =  '*';
            var url     =   Common.base_url()+'save-onboard-trustee-director';   
            Axios.post(
                url, 
                form_data, 
                {
                    headers: {  
                        'Content-Type': 'multipart/form-data'
                    } 
                }
            )
            .then(response => { 
                this.director_list(this.active_trustee_id);   
                this.$emit('recipientFetch');
                // this.$emit('directorFetch');
                if(response.data.success==200){
                    this.form['director_name']=null;
                    this.form['director_email']=null;
                    this.form['director_phone_no']=null; 

                    setTimeout(() => this.show_dir_form =0, 800);   
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.success(response.data.message);   
                }else{ 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.error(response.data.message);   
                } 
            })
            .catch(error => {
                setTimeout(() => this.load_on=0, 500);   
            });   
        } 

        ask_director_delete(director_id,trustee_id){
            this.active_director_id = director_id;
            this.active_trustee_id = trustee_id;
            this.openModal('delete_director');
        }
        delete_director(director_id,trustee_id){ 
            setTimeout(() => this.load_on=1, 100); 
            var form_records  = JSON.parse(JSON.stringify((this.form)));   
            let form_data = new FormData();  
            form_data.append('user_token'           ,this.local_storage['access-token']);              
            form_data.append('director_id'          ,director_id);            
            form_data.append('trustee_id'          ,trustee_id); 
              
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] =  '*';
            var url     =   Common.base_url()+'delete-onboard-trustee-director';   
            Axios.post(
                url, 
                form_data, 
                {
                    headers: {  
                        'Content-Type': 'multipart/form-data'
                    } 
                }
            )
            .then(response => { 
                this.director_list(trustee_id);   
                this.$emit('recipientFetch');
                this.active_trustee_id = 0 ;
                this.closeModal('delete_director'); 
                // this.$emit('directorFetch'); 
                if(response.data.success==200){   
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.success(response.data.message); 
                }else{ 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.error(response.data.message);   
                } 
            })
            .catch(error => {
                setTimeout(() => this.load_on=0, 500);   
            });   
        } 
 

        openModal(id: any) {
            $('#' + id).addClass('d-block');
            $('#' + id).addClass('show');
            $('#' + id).css('overflow-y', 'scroll');
        }
        closeModal(id: any,reload=false) {
            $('#' + id).removeClass('d-block');
            $('#' + id).addClass('d-none');
            $('#' + id).removeClass('show');
            if(reload==true){
                location.reload();
            }
        }  

        

    }
