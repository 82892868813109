
  import { Options, Vue } from 'vue-class-component';
  import SecuritiesBasic from '@/components/securities/SecuritiesBasic.vue';
  import Common from '@/services/common.js';
  import moment from 'moment';
   
  @Options({
    props: { 
      fum_id:     Number, 
    }, 
    components: {  
      'basic' : SecuritiesBasic, 
    }  
  })
  export default class SecuritiesInvestors extends Vue { 
    fum_id;
    local_storage:any; 
    investors:any=[]; 
    coupons:any=[];  

    data () {      
      return {   
        fum_id:this.fum_id, 
        investors:this.investors, 
        coupons:this.coupons, 
      }
 
    }    
    created() {
      this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));
      return true;
    }
    mounted() {
        this.fum_id   
        this.get_securities_investors() 
    }  

    get_securities_investors(){
      Common.get_securities_investors(this.local_storage['access-token'],this.fum_id,'')
        .then(response => {
          if (typeof response.data !== 'undefined' && response.data.success == 200) {           
            var investors = response.data.investors;    
            var coupons =[];
            this.coupons = [];
            for (let i = 0; i < investors.length; i++) { 
              investors[i]['investor_request_date']=moment(investors[i]['investor_request_date']).format('DD/MM/YYYY'); 
              
              investors[i]['myinvestment_maturity_date']=moment(investors[i]['myinvestment_maturity_date']).format('DD/MM/YYYY'); 

 
              investors[i]['investor_inv_amount']=parseFloat(investors[i]['investor_inv_amount']).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})
              
              investors[i]['principle_paid']=parseFloat(investors[i]['principle_paid']).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2})
  
              if(typeof investors[i]['coupon_records']!='undefined' && investors[i]['coupon_records'].length >0 ){ 
                var coupon_list = investors[i]['coupon_records'];
                for (var j = 0; j <coupon_list.length ; j++) { 

                  investors[i]['coupon_records'][j]['coupon_date'] = moment(coupon_list[j]['coupon_date']).format('DD/MM/YYYY'); 
                  investors[i]['coupon_records'][j]['paid_coupon_amount'] =parseFloat(investors[i]['coupon_records'][j]['paid_coupon_amount']).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                  investors[i]['coupon_records'][j]['pending_coupon_amount'] =parseFloat(investors[i]['coupon_records'][j]['pending_coupon_amount']).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                  investors[i]['coupon_records'][j]['total_coupon_amount'] =parseFloat(investors[i]['coupon_records'][j]['total_coupon_amount']).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});   

                  this.coupons.push(investors[i]['coupon_records'][j]);
                }  
              }   
            }   
            this.investors  = investors; 
          }
        }).catch(e => {
          console.log(e)
      });
    }
  }
