
  import { Options, Vue } from 'vue-class-component';
   
  @Options({
    props: {
        customer_accounts: [Number, String],
        default_currency_icon: String,
        total_active_default_balance: Number,
    }
  })
  export default class Portfolio_card extends Vue {
    customer_accounts 
    default_currency_icon 
    total_active_default_balance 
    data () {     
      
      return {  
        customer_accounts:this.customer_accounts,
        default_currency_icon:this.default_currency_icon,
        total_active_default_balance:this.total_active_default_balance,
      }
 
    }    
    created() {       
      return true;
    }
    mounted() {
        this.customer_accounts 
        this.default_currency_icon 
        this.total_active_default_balance  
    } 
 

  }
