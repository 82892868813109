
    import { Options, Vue } from 'vue-class-component';   
    import Common from '@/services/common.js';  
    import App from '../App.vue';  
    import $ from 'jquery';   
    import Shareholderbasic from '@/components/Shareholderbasic.vue';    
  
    import { Form, Field,ErrorMessage } from 'vee-validate';
    import { useToast } from "vue-toastification";

    import Axios from "axios";
    @Options({
        components: {  
            Shareholderbasic, 
            Form,
            Field,
            ErrorMessage
        }, 
    })

    export default class Onboard extends Vue { 
        load_on:any     =   0;
        prev_path :any  =   ''; 
        STORAGE_KEY     =   "caprock-local";   
 
        toast:any = useToast();
        local_storage:any;
        in_verification : any = 0;
        in_onboarding : any=0;
        
        is_director     : any=0;
        amad:any    =   false;
        token :any  =   false;

        company_type  :any= '';
        account_type  :any= '';

        data () {  
            return {   
                load_on:this.load_on, 
                is_director:this.is_director, 
                company_type : this.company_type,
                account_type : this.account_type,
                token:this.token,
                amad:this.amad,
            }
        }   

        logout(){
            localStorage.clear(); 
            this.token='';   
            alert('Invalid URL please contact caprock team'); 
            location.href="https://caprock.com.au";
          // location.href='/sign-in'; 
        } 
        

        beforeCreate(){
          this.load_on=1;
        }

        created() {     
            setTimeout(() => this.load_on=0, 500);

            if (typeof this.$route.query.token!='undefined' && this.$route.query.token!='' && this.$route.query.token!=null && typeof this.$route.query.amad!='undefined' && this.$route.query.amad!='' && this.$route.query.amad!=null) {
                this.amad   = this.$route.query.amad;
                this.token  = this.$route.query.token; 
 

                Common.get_shareholder_info(this.token,this.amad).then(response => {   
                    
                    if(response.data.success==200){
                        
                        if (response.data.s_status==1) {
                            alert('Application completed');
                            location.href="https://caprock.com.au";
                        }  

                        this.account_type = response.data.account_type
                        if (typeof (response.data.account_type)!='undefined' && (response.data.account_type==1 || response.data.account_type=='1' )) {
                            this.process_step(response.data.account_type,0);
                        }else{
                            if (typeof (response.data.company_type)!='undefined' && response.data.company_type>0) {
                                this.process_step(response.data.account_type,response.data.company_type);
                            } 
                        } 
                    }else{
                        // alert('Invalid URL please contact Caprock Team')
                        this.logout();
                    }
 
                    
                    return true;
                }).catch(e => {console.log(e) }); 
                  
           



            } else{
                // alert('Invalid URL please contact caprock team');
                this.logout();
            }
            if(!this.token || !this.amad){   
                this.logout();
            }  

 

          document.title = 'Onboard || Caprock Merchant Platform';   
          return true;
        } 


        validateEmail(value) { 
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (typeof value!='undefined' && !regex.test(value)) { 
                return 'This field must be a valid email'; 
            } 
            return true;
        }  
        requiredField(value) { 
            if (!value) {
                return 'This field is required';
            } 
            return true;
        }
        selectField(value) { 
            if (!value || value=='') {
                return 'Please select one of option';
            } 
            return true;
        }
        mobileField(value){ 
            if(typeof value!='undefined'){
                var phoneNum = value.replace(/[^\d]/g, '');
                if(phoneNum.length <7 || phoneNum.length > 12) {  
                    return 'This field must be a valid mobile number';
                }  
                return true;
            }
            return true; 
        } 


        app_proceed(){  
            this.load_on        =   1;  
            let form_data       =   new FormData();  
            form_data.append('user_token'   ,   this.token); 
            form_data.append('amad'         ,   this.amad); 
            form_data.append('company_type' ,   this.company_type); 
            form_data.append('account_type' ,   this.account_type); 
           
            
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            var url =Common.base_url()+'shareholder-company-type-update';
            Axios.post(
                url, 
                form_data, 
                {
                    headers: {  
                      'Content-Type': 'multipart/form-data'
                    }, 
                    onUploadProgress: function( progressEvent:any ) {
                        var loaded:number   =   progressEvent.loaded;
                        var total:number    =   progressEvent.total;
                        var per             =   parseInt((loaded/total *100).toFixed());  
                    }.bind(this)
                }
            ).then(response => { 
                if(response.data.success==200){ 
                    var company_type    =   response.data.c_type; 
                    var account_type    =   response.data.a_type;    
                    this.process_step(account_type,company_type); 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.success(response.data.message);
                }else{ 
                  setTimeout(() => this.load_on=0, 500);  
                  this.toast.error(response.data.message);
                } 
            }).catch(error => { 
                setTimeout(() => this.load_on=0, 500);  
                this.toast.error('Internal Error');
            });  
             
           
        }


        process_step(account_type,company_type){ 
            if(account_type==1 || account_type=='1'){
                // alert('Individual Profile');
                this.$router.push('/shareholder-individual-app?token='+this.token+'&amad='+this.amad);  
            }else{
                if(company_type==1 || company_type=='1'){
                    this.$router.push('/shareholder-australian-domestic-app?token='+this.token+'&amad='+this.amad);  
                }
                if(company_type==2 || company_type=='2'){
                    this.$router.push('/shareholder-non-aus-asic-app?token='+this.token+'&amad='+this.amad);  
                }
                if(company_type==3 || company_type=='3'){
                    this.$router.push('/shareholder-non-aus-app?token='+this.token+'&amad='+this.amad);  
                } 
            }
            /*if(company_type==2 || company_type=='2'){  
                this.$router.push('/shareholder-australian-domestic-app');  
            }  
            if(company_type==3 || company_type=='3'){
                this.$router.push('/shareholder-australian-domestic-app');   
            }*/ 
        } 
    } 
