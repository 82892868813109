
    import { Options, Vue } from 'vue-class-component';
    import {ref } from 'vue';
    import Common from '@/services/common.js';
    import moment from 'moment';  
    import { useToast } from "vue-toastification";
    import Axios from "axios";     
    import { Form, Field,ErrorMessage ,useForm} from 'vee-validate';
    import $ from 'jquery'; 

    @Options({ 
        components: { 
            Form,
            Field,
            useForm,
            ErrorMessage
        },
        props: {
            countries     : [Number, String],
            current_step:     Number,  
            load_on:     Number,  
        },  
    })
    
    export default class Shareholders extends Vue {  
        current_step;  
        show_form=0;
        countries; 
        load_on;
        active_shareholder_id =0;
        form = {}; 
        shareholders       :any        =   [];
        toast:any                   = useToast();
        local_storage   :any;  
        data () {     
           
            return {      
                countries       :   this.countries,  
                active_shareholder_id:this.active_shareholder_id,
                show_form       :   this.show_form,  
                current_step    :   this.current_step,  
                load_on         :   this.load_on,  
                shareholders       :   this.shareholders, 
            } 
        }    
        created() {    
            this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));     
            return true;
        }
        mounted() { 
            this.shareholder_list();  
        }  
 
        
        validateEmail(value) { 
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (typeof value!='undefined' && !regex.test(value)) { 
                return 'This field must be a valid email'; 
            } 
            return true;
        }  
        requiredField(value) { 
            if (!value) {
                return 'This field is required';
            } 
            return true;
        }
        selectField(value) { 
            if (!value || value=='') {
                return 'Please select one of option';
            } 
            return true;
        } 
        mobileField(value){ 
            if(typeof value!='undefined'){
                var phoneNum = value.replace(/[^\d]/g, '');
                if(phoneNum.length <7 || phoneNum.length > 12) {  
                    return 'This field must be a valid mobile number';
                }  
                return true;
            }
            return true; 
        } 
        async shareholder_list(){
            await Common.get_shareholders(this.local_storage['access-token'])
                .then(response => { 
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        var shareholders   =   response.data.shareholders; 
                        this.shareholders  =   shareholders;   
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        /*reset_shareholder_form(){
            this.$nextTick(() => { 
                var tBox :any= this.$refs.shareholder_form;
                tBox.resetForm(); 
            }); 
        }*/

        save_shareholder(){  
              

            setTimeout(() => this.load_on=1, 100); 
            var form_records  = JSON.parse(JSON.stringify((this.form)));   
            let form_data = new FormData();  
            form_data.append('user_token'           ,this.local_storage['access-token']);              
            
            form_data.append('shareholder_type'        ,form_records['shareholder_type']);
            form_data.append('shareholder_percentage'        ,form_records['shareholder_percentage']);
            form_data.append('shareholder_name'        ,form_records['shareholder_name']);
            form_data.append('shareholder_email'       ,form_records['shareholder_email']);
            form_data.append('shareholder_phone_no'    ,form_records['shareholder_phone_no']);
              
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] =  '*';
            var url     =   Common.base_url()+'save-onboard-shareholder';   
            Axios.post(
                url, 
                form_data, 
                {
                    headers: {  
                        'Content-Type': 'multipart/form-data'
                    }, 
                    onUploadProgress: function( progressEvent:any ) {
                        var loaded:number   =   progressEvent.loaded;
                        var total:number    =   progressEvent.total;
                        var per             =   parseInt((loaded/total *100).toFixed());  
                    }.bind(this)
                }
            )
            .then(response => { 
                if(response.data.success==200){
                    this.shareholder_list();
                    this.$emit('shareholderFetch');  
                    this.$nextTick(() => { 
                        var tBox :any= this.$refs.shareholder_form;
                        tBox.resetForm(); 
                    });  
                    setTimeout(() => this.show_form =0, 800);   
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.success(response.data.message);   
                }else{ 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.error(response.data.message);   
                } 
            })
            .catch(error => {
                setTimeout(() => this.load_on=0, 500);   
            });   
        } 

        ask_delete(shareholder_id){
            this.active_shareholder_id = shareholder_id;
            this.openModal('delete_shareholder');
        }


        openModal(id: any) {
            $('#' + id).addClass('d-block');
            $('#' + id).addClass('show');
            $('#' + id).css('overflow-y', 'scroll');
        }
        closeModal(id: any,reload=false) {
            $('#' + id).removeClass('d-block');
            $('#' + id).addClass('d-none');
            $('#' + id).removeClass('show');
            if(reload==true){
                location.reload();
            }
        }  

        delete_shareholder(shareholder_id){ 
            setTimeout(() => this.load_on=1, 100); 
            var form_records  = JSON.parse(JSON.stringify((this.form)));   
            let form_data = new FormData();  
            form_data.append('user_token'           ,this.local_storage['access-token']);              
            form_data.append('shareholder_id'          ,shareholder_id); 
              
            Axios.defaults.headers.common['Access-Control-Allow-Origin'] =  '*';
            var url     =   Common.base_url()+'delete-onboard-shareholder';   
            Axios.post(
                url, 
                form_data, 
                {
                    headers: {  
                        'Content-Type': 'multipart/form-data'
                    }, 
                    onUploadProgress: function( progressEvent:any ) {
                        var loaded:number   =   progressEvent.loaded;
                        var total:number    =   progressEvent.total;
                        var per             =   parseInt((loaded/total *100).toFixed());  
                    }.bind(this)
                }
            )
            .then(response => { 
                this.active_shareholder_id=0 ;
                this.closeModal('delete_shareholder');
                if(response.data.success==200){
                    this.shareholder_list();
                    this.$emit('shareholderFetch'); 
                    this.$nextTick(() => { 
                        var tBox :any= this.$refs.shareholder_form;
                        tBox.resetForm(); 
                    }); 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.success(response.data.message); 
                }else{ 
                    setTimeout(() => this.load_on=0, 500);  
                    this.toast.error(response.data.message);   
                } 
            })
            .catch(error => {
                setTimeout(() => this.load_on=0, 500);   
            });   
        } 

    }
