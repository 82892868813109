
  import { Options, Vue } from 'vue-class-component'; 
  import SecuritiesBasic from '@/components/securities/SecuritiesBasic.vue';
  import Common from '@/services/common.js';
  import moment from 'moment';  
   
   
  @Options({
    props: { 
      fum_id:     Number, 
      doc_type:   String, 
    },

    components: {  
      'basic' : SecuritiesBasic,  
    },

  })
  export default class SecuritiesDocs extends Vue {  
    fum_id;
    local_storage:any; 
    transactions:any=[];  
 
    
    docs :any =  []; 
    doc_type  ; 
    timer :any= undefined;



    data () {      
      return {   
        fum_id:this.fum_id,  
        doc_type:this.doc_type,  
      }
 
    }     
    
    created() { 
      this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));
      return true;
    }
    
    mounted() {
        this.fum_id;   
        this.doc_type;   
        /*this.get_securities_fum(); */
        this.get_documents(''); 
    }  
    
    open_link(id){
      var link = (document.getElementById("URL"+id) as HTMLInputElement).value; 
      window.open(link,'_blank');
    }

    get_documents(search) {   
        Common.get_securities_documents(this.local_storage['access-token'],this.fum_id,this.doc_type,search)
            .then(response => {
                this.docs=[]; 
                if (typeof response.data !== 'undefined' && response.data.success == 200) {    
                    var sdocs =response.data.docs; 
                    for (let i = 0; i < sdocs.length; i++) {                           
                        sdocs[i]['doc_time'] =moment(sdocs[i]['created_at']).format('DD MMM, YYYY'); 
                    } 
                    this.docs=sdocs;
                }
            }).catch(e => {
              console.log(e)
        });
    }

    textSearch(e:any){
        clearTimeout(this.timer) 
        this.timer = setTimeout(() => {
            this.get_documents(e.target.value)
             
        }, 300)
    }
  }
