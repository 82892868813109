
    import { Options, Vue } from 'vue-class-component'; 
   
    @Options({
        props: { 
            fum_id  :   Number, 
            basic   :   Object, 
        }
    })
    
    export default class AcquisitionBasic extends Vue { 
        fum_id;
        local_storage:any; 
        basic;
        data () {      
          return {   
            fum_id  :this.fum_id, 
            basic   :this.basic, 
          }
     
        }    
        created() {    
            this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));    
            return true;
        }
        mounted() {
            this.fum_id;   
            this.basic;     
        }   
    }
