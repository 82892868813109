
    import { Options, Vue } from 'vue-class-component';
    import HelloWorld from '@/components/HelloWorld.vue';
    import Basic from '@/components/customer/Basic.vue';
    import Rightside from '@/components/customer/Rightside.vue';
    import Common from '@/services/common.js';
    import moment from 'moment';
    import $ from 'jquery';  
    import VueApexCharts from "vue3-apexcharts";
    
    import Portfolio_card from '@/components/accounts/Portfolio_card.vue';
    
    import SecuritiesHeader from '@/components/securities/SecuritiesHeader.vue';
    import SecuritiesFum from '@/components/securities/SecuritiesFum.vue';
    import SecuritiesSecurities from '@/components/securities/SecuritiesSecurities.vue';
    import SecuritiesInvestors from '@/components/securities/SecuritiesInvestors.vue';
    import SecuritiesDocs from '@/components/securities/SecuritiesDocs.vue';
    import SecuritiesAcquisition from '@/components/securities/SecuritiesAcquisition.vue';
    import SecuritiesFees from '@/components/securities/SecuritiesFees.vue';
  
 
  @Options({
    components: {
        HelloWorld, 
        'right-side':Rightside,
        'apexchart': VueApexCharts,
        Basic,
        
        'portfolio-card': Portfolio_card,
        'sheader'       : SecuritiesHeader,
        'fum'           : SecuritiesFum,
        'securities'    : SecuritiesSecurities,
        'investors'     : SecuritiesInvestors,
        'docs'          : SecuritiesDocs,
        'acquisition'   : SecuritiesAcquisition,
        'fees'          : SecuritiesFees,

    },
  })
  export default class Securities extends Vue { 
    
    STORAGE_KEY = "caprock-local";   
    user_name = ''; 
    user_info = {}; 
    customer_accounts   =   []; 
    total_ac_balance        =   0;  
    total_active_default_balance    =   0;  
    total_archive_default_balance   =   0;  
    default_currency_icon           =   '$';  
    default_currency                =   'AUD';  
    subac :any =  [];
    selected_subac :any =  [];
    transactions :any =  [];
    all_transactions :any =  [];
    active_subac :any = '';
    local_storage:any;
    load_on:any=0;
    
    security = {
        ac_balance:0,
        default_currency_balance    :0,
        userac_name:'Securities',
        userac_reference_code:'#',
        userac_establish_date:''
    }; 

    all_securitys : any =[];

    fund_managers   :   any =[];
    active_fum_id   :   any=''; 
    active_tab      :   any=''; 


    timer :any= undefined;
    /*Chart Var*/
        last_12_months:any = [
            moment().subtract(11, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(10, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(9, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(8, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(7, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(6, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(5, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(4, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(3, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(2, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(1, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(0, "month").startOf("month").format('MMM, YYYY'), 
        ];  
        active_chart=1;
        on_chart:any=0;
        chart1:any = [0,0,0,0]
        chart2:any = [0,0,0]
        chart3:any = [0,0,0,0,0,0]
        chart4:any = [0,0,0,0,0,0,0,0,0,0,0,0]
        chart5:any = [0,0]
        chartOptions1= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#494949',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: ["Week 1", "Week 2", "Week 3", "Week 4", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#494949'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series1 = [
            {
                name: "Balance",
                data: this.chart1
            },
        ];
 
        chartOptions2= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#494949',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: [
                    moment().subtract(2, "month").startOf("month").format('MMM'), 
                    moment().subtract(1, "month").startOf("month").format('MMM'), 
                    moment().subtract(0, "month").startOf("month").format('MMM'), 
                ],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#494949'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series2 = [
            {
                name: "Balance",
                data: this.chart2
            },
        ];

        chartOptions3= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#494949',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: [
                    moment().subtract(5, "month").startOf("month").format('MMM'),  
                    moment().subtract(4, "month").startOf("month").format('MMM'), 
                    moment().subtract(3, "month").startOf("month").format('MMM'), 
                    moment().subtract(2, "month").startOf("month").format('MMM'), 
                    moment().subtract(1, "month").startOf("month").format('MMM'), 
                    moment().subtract(0, "month").startOf("month").format('MMM'), 
                ],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#494949'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series3 = [
            {
                name: "Balance",
                data: this.chart3
            },
        ];


        chartOptions4= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#494949',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: this.last_12_months
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#494949'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series4 = [
            {
                name: "Balance",
                data: this.chart4
            },
        ];

        chartOptions5= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#494949',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: [
                    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" 
                ],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#494949'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series5 = [
            {
                name: "Balance",
                data: this.chart5
            },
        ];

    /*Chart End*/
     
    beforeCreate(){
        this.load_on=1;
    } 
    created() {     
        // setTimeout(() => this.load_on=0, 500);
        var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
        if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
            /*console.log('Securities');*/
        }else{ 
            localStorage.removeItem(this.STORAGE_KEY); 
            this.$router.push('/sign-in'); 
        } 
        document.title = "Securities || Caprock Merchant Platform";
        localStorage.setItem(
            'active_page', 'securities'
        ); 

    } 
    async mounted(){
        if(localStorage.getItem('selected_security_subac_id')!=null){
            this.active_subac= localStorage.getItem('selected_security_subac_id'); 
        }else{
            this.active_subac=0;
        } 
        this.user_name=''; 
        this.get_user_accounts(); 
        this.get_user_info(); 
        this.get_account_detail('securities'); 
        await this.get_fund_managers();  

        var f_id = localStorage.getItem('selected_fum_id');
        var f_tab= localStorage.getItem('selected_securities_tab');
        

        if(typeof f_id!='undefined' && typeof f_tab!='undefined'){
            this.active_fum_id      =   f_id;
            this.active_tab         =   f_tab;
            this.change_fum(f_id,f_tab);
        } 

        setTimeout(() => this.load_on=0, 500); 
 

    }

    change_fum(fum_id,tab){   
        localStorage.setItem('selected_fum_id',fum_id); 
        localStorage.setItem('selected_securities_tab',tab); 

        this.active_fum_id  =   fum_id;
        this.active_tab     =   tab;  
        if(typeof tab=='undefined' || tab==null || tab=='' || typeof fum_id=='undefined' || fum_id==null || fum_id==''){ 
            this.active_tab     =   '';             
        } 
    }


    open_link(id){
        var link = (document.getElementById("URL"+id) as HTMLInputElement).value; 
        window.open(link,'_blank');
    }

    get_user_accounts() { 
        Common.get_user_accounts(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                    this.total_ac_balance   =   response.data.t_balance.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) ;  
                    
                    this.total_active_default_balance=0;
                    this.total_archive_default_balance=0;

                    var ac_data = response.data.accounts; 
                    for (let i = 0; i < ac_data.length; i++) {   
                        if(ac_data[i]['userac_status']==1){ 
                            this.total_active_default_balance= this.total_active_default_balance+ ac_data[i]['default_currency_balance'];   
                        }else{
                            this.total_archive_default_balance= this.total_archive_default_balance+ ac_data[i]['default_currency_balance'];   
                        } 
                    } 
                    for (let i = 0; i < ac_data.length; i++) {  
                        ac_data[i]['ac_percent']=(100*ac_data[i]['default_currency_balance']/this.total_active_default_balance); 
                    } 
                    this.customer_accounts=ac_data;
                     
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_user_info() { 
        Common.get_user_info(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    var user_inf=response.data.profile; 
                    user_inf.join_date=user_inf.join_date?moment(user_inf.join_date).format('DD MMM YYYY '):'';
                    this.user_info=user_inf;  
                    this.default_currency       =   user_inf.default_currency;
                    this.default_currency_icon  =   user_inf.default_currency_icon;
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_account_detail(ac_name) {   
        Common.get_account_detail(this.local_storage['access-token'],ac_name)
            .then(response => {
                this.subac=[]; 
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    // this.security = response.data.ac_info;
                    var security            = response.data.ac_info; 
                    security.userac_establish_date=moment(security.userac_establish_date).format('DD MMM, YYYY ');
                    this.security           = security;
                    this.subac              = response.data.ac_data.subaccounts;
                    this.selected_subac     = response.data.ac_data.subaccounts;


                    var s_data = this.subac;  
                    for (let i = 0; i < s_data.length; i++) { 
                        var d_data = s_data[i].deposits;   
                        for (let j = 0; j < d_data.length; j++) { 
                            d_data[j]['t_time']=moment.unix(d_data[j]['trans_time']).utcOffset('+11').format('DD MMM YYYY | HH:mm');
                        } 
                        s_data[i].deposits=d_data;

                        var w_data = s_data[i].withdrawls;   
                        for (let j = 0; j < w_data.length; j++) { 
                            w_data[j]['t_time']=moment.unix(w_data[j]['trans_time']).utcOffset('+11').format('DD MMM YYYY | HH:mm');
                        } 
                        s_data[i].withdrawls=w_data;


                        var i_data = s_data[i].investments;   
                        for (let j = 0; j < i_data.length; j++) { 
                            i_data[j]['t_time']=moment.unix(i_data[j]['trans_time']).utcOffset('+11').format('DD MMM YYYY | HH:mm');

                            var t_date = i_data[j].trans_date;
                            var date = moment(t_date).utcOffset('+11').format();
                            var date1:any = moment(date).format("X");
                            var date2:any = moment().unix();

                            var diff   =Math.abs(date2-date1);

                            var years  = Math.floor(diff / (365*60*60*24));
                            var months = Math.floor((diff - years * 365*60*60*24) / (30*60*60*24));
                            var days   = Math.floor((diff - years * 365*60*60*24 - months*30*60*60*24)/ (60*60*24)); 
 
                            i_data[j]['t_days']=days;
                            i_data[j]['t_months']=months;
                            i_data[j]['t_years']=years;

                            i_data[j]['investment_period']='';

                            if(years>0){
                                i_data[j]['investment_period'] = years+ ((years>1)? ' yrs' :' yr') 
                            }
                            else if(months>0){
                                i_data[j]['investment_period'] = months+ ((months>1)? ' months' :' month') 
                            }
                            else{
                                i_data[j]['investment_period'] = days+ ((days>1)? ' days' :' day') 
                            }


                            i_data[j]['roi'] = (s_data[i].subac_currency_balance+s_data[i].security_growth+s_data[i].security_yield+s_data[i].subac_currency_investment)/i_data[j].trans_currency_total; 
                            i_data[j]['irr'] = i_data[j]['roi']/((years>=1)?years:1);



                        } 
                        s_data[i].investments=i_data;
                    } 
                    this.subac=this.selected_subac=s_data   
                    this.filterTrans(this.active_subac);
                    /*Chart Data*/
                        var chart_info =response.data.chart_data;  
                        this.chart1 = chart_info.last_month_chart;  
                        var new_series1 = [
                            {
                                name: "Balance",
                                data: this.chart1,
                            },
                        ]; 
                        this.series1=new_series1  

                        this.chart2 = chart_info.three_month_chart;
                        var new_series2 = [
                            {
                                name: "Balance",
                                data: this.chart2,
                            },
                        ]; 
                        this.series2=new_series2 

                        this.chart3 = chart_info['six_month_chart'];
                        var new_series3 = [
                            {
                                name: "Balance",
                                data: this.chart3,
                            },
                        ]; 
                        this.series3=new_series3  

                        this.chart4 = chart_info['year_chart'].reverse();
                        var new_series4 = [
                            {
                                name: "Balance",
                                data: this.chart4,
                            },
                        ]; 
                        this.series4=new_series4 
 
                         
                        this.chart5 = [];
                        var labelss :any= [];
                        if(chart_info['all_chart'].length==1){
                            this.chart5.push(0);
                            labelss.push((chart_info['all_chart'][0]['year']-1));
                        }
                        for (let i = 0; i < chart_info['all_chart'].length; i++) {  
                            this.chart5.push(chart_info['all_chart'][i]['balance']);
                            labelss.push(chart_info['all_chart'][i]['year']);
                        }
                        var newchartOptions5= {
                            chart: {
                                id: "vuechart-example",
                                type: "line",
                                height: 180,
                                foreColor: '#494949',
                                toolbar: {
                                    show: false
                                },
                                stacked:true
                            },
                            xaxis: {
                                categories: labelss,
                            }, 
                            yaxis: { 
                                axisBorder: {
                                    show: false
                                },
                                axisTicks: {
                                    show: false,
                                }, 
                                labels: {
                                    show: false,
                                    formatter: function (val) {
                                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                                    }
                                },
                                min: 0,
                                forceNiceScale: true,

                            },
                            plotOptions: {
                                bar: {
                                    borderRadius: 4,
                                    columnWidth: '35%',
                                    dataLabels: {
                                        position: 'top', 
                                    },
                                }
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                    return "$" + val;
                                },
                                offsetY: -20,
                                style: {
                                    fontSize: '12px',
                                    colors: ['#FFF']
                                }
                            }, 
                            colors: ['#494949'], 
                            grid: {
                                show: false,
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },  
                            }, 
                        }; 
 
                        var new_series5 = [
                            {
                                name: "Balance",
                                data: this.chart5,
                            },
                        ]; 
                        this.chartOptions5=newchartOptions5; 
                        this.series5=new_series5;
                        this.on_chart=1 ;
                    /*Chart End*/
                } 
            }).catch(e => {
              console.log(e)
        }); 
    }


    filterTrans(subac_id){    
         
        // var subac_id = event.target.value; 
        if(typeof subac_id != "undefined" && subac_id>0){
            localStorage.setItem(
                'selected_security_subac_id', subac_id
            ); 
            this.active_subac = subac_id
            var f_data:any =[];
            var t_data:any =this.subac;
            for (let i = 0; i < t_data.length; i++) { 
                if(t_data[i]['subac_id']==subac_id){
                    f_data[i]=t_data[i]; 
                } 
            } 
            f_data = f_data.filter(value => Object.keys(value).length !== 0); 
            this.selected_subac=f_data; 
        }
    }

    async get_fund_managers(){ 
        await Common.get_fund_managers(this.local_storage['access-token'])
            .then(response => { 
                this.fund_managers = response.data.fund_managers;  
            }).catch(e => {
              console.log(e)
        });
    }

    
    data () {    
        return {  
            customer_accounts   :this.customer_accounts,
            user_info           :this.user_info,   
            default_currency_icon:this.default_currency_icon,
            total_active_default_balance:this.total_active_default_balance,

            active_fum_id   : this.active_fum_id,
            active_tab      : this.active_tab, 
            load_on : this.load_on
        } 
    }    
  
  }
