
    import { Options, Vue } from 'vue-class-component';
    import HelloWorld from '@/components/HelloWorld.vue';
    import Basic from '@/components/customer/Basic.vue';
    import Rightside from '@/components/customer/Rightside.vue';
    import Common from '@/services/common.js';
    import moment from 'moment';
    import $ from 'jquery';  
    import VueApexCharts from "vue3-apexcharts";
    

    import Portfolio_card from '@/components/accounts/Portfolio_card.vue';

    import MyinvHeader from '@/components/myinvestments/MyinvHeader.vue';
    import MyinvInvestment from '@/components/myinvestments/MyinvInvestment.vue';
    import MyinvInterest from '@/components/myinvestments/MyinvInterest.vue'; 
    import MyinvDocs from '@/components/myinvestments/MyinvDocs.vue'; 
  
 
  @Options({
    components: {
        HelloWorld, 
        'right-side':Rightside,
        'apexchart': VueApexCharts,
        Basic,

        'portfolio-card': Portfolio_card, 
        'iheader'       : MyinvHeader,
        'investment'    : MyinvInvestment,
        'interest'      : MyinvInterest, 
        'docs'          : MyinvDocs  

    },
  })
  export default class MyInvestment extends Vue { 
    STORAGE_KEY = "caprock-local";   
    user_name = ''; 
    user_info = {}; 
    customer_accounts   =   []; 
    total_ac_balance        =   0;  
    total_active_default_balance    =   0;  
    total_archive_default_balance   =   0;  
    default_currency_icon           =   '$';  
    default_currency                =   'AUD';  
    subac :any =  [];
    selected_subac :any =  [];
    transactions :any =  [];
    docs:any =   [];  
    doc_on= 0;  
    all_transactions :any =  [];
    active_subac :any = '';
    local_storage:any;
    load_on:any=0;
    
    myinvestment = {
        ac_balance:0,
        default_currency_balance    :0,
        userac_name:'My Investment',
        userac_reference_code:'#',
        userac_establish_date:''
    }; 

    all_myinvestments :any =[];
 
    myinvestments   :   any =[];
    active_myinv_id :   any=''; 
    active_tab      :   any=''; 


    timer :any= undefined;
    /*Chart Var*/
        last_12_months:any = [
            moment().subtract(11, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(10, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(9, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(8, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(7, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(6, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(5, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(4, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(3, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(2, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(1, "month").startOf("month").format('MMM, YYYY'), 
            moment().subtract(0, "month").startOf("month").format('MMM, YYYY'), 
        ];  
        active_chart=1;
        on_chart:any=0;
        chart1:any = [0,0,0,0]
        chart2:any = [0,0,0]
        chart3:any = [0,0,0,0,0,0]
        chart4:any = [0,0,0,0,0,0,0,0,0,0,0,0]
        chart5:any = [0,0]
        chartOptions1= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#494949',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: ["Week 1", "Week 2", "Week 3", "Week 4", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#494949'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series1 = [
            {
                name: "Balance",
                data: this.chart1
            },
        ];
 
        chartOptions2= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#494949',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: [
                    moment().subtract(2, "month").startOf("month").format('MMM'), 
                    moment().subtract(1, "month").startOf("month").format('MMM'), 
                    moment().subtract(0, "month").startOf("month").format('MMM'), 
                ],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#494949'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series2 = [
            {
                name: "Balance",
                data: this.chart2
            },
        ];

        chartOptions3= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#494949',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: [
                    moment().subtract(5, "month").startOf("month").format('MMM'),  
                    moment().subtract(4, "month").startOf("month").format('MMM'), 
                    moment().subtract(3, "month").startOf("month").format('MMM'), 
                    moment().subtract(2, "month").startOf("month").format('MMM'), 
                    moment().subtract(1, "month").startOf("month").format('MMM'), 
                    moment().subtract(0, "month").startOf("month").format('MMM'), 
                ],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#494949'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series3 = [
            {
                name: "Balance",
                data: this.chart3
            },
        ];


        chartOptions4= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#494949',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: this.last_12_months
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#494949'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series4 = [
            {
                name: "Balance",
                data: this.chart4
            },
        ];

        chartOptions5= {
            chart: {
                id: "vuechart-example",
                type: "line",
                height: 180,
                foreColor: '#494949',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: [
                    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" 
                ],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#494949'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series5 = [
            {
                name: "Balance",
                data: this.chart5
            },
        ];

    /*Chart End*/
    
    created() {      
        var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
        if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
            /*console.log('My Investment');*/
        }else{ 
            localStorage.removeItem(this.STORAGE_KEY); 
            this.$router.push('/sign-in'); 
        } 
        document.title = "My Investment || Caprock Merchant Platform";
        localStorage.setItem(
            'active_page', 'my-investment'
        ); 
    }
   async mounted(){
        if(localStorage.getItem('selected_myinvestment_subac_id')!=null){
            this.active_subac= localStorage.getItem('selected_myinvestment_subac_id'); 
        }else{
            this.active_subac=0;
        } 
        this.user_name=''; 
        this.get_user_accounts(); 
        this.get_user_info(); 
        this.get_account_detail('my-investment'); 

        await this.get_myinvestments(); 


        var f_id = localStorage.getItem('selected_myinv_id');
        var f_tab= localStorage.getItem('selected_myinvestment_tab');  
        if(typeof f_id!='undefined' && typeof f_tab!='undefined'){
            this.active_myinv_id      =   f_id;
            this.active_tab         =   f_tab;
            this.change_myinv(f_id,f_tab);
        }  
        setTimeout(() => this.load_on=0, 500);  
    }

    open_link(id){
        var link = (document.getElementById("URL"+id) as HTMLInputElement).value; 
        window.open(link,'_blank');
    }

    get_user_accounts() { 
        Common.get_user_accounts(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                    var total_d_balance = 0 ;

                    this.total_ac_balance   =   response.data.t_balance.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) ;  
                    this.total_active_default_balance=0;
                    this.total_archive_default_balance=0;

                    var ac_data = response.data.accounts; 
                    for (let i = 0; i < ac_data.length; i++) { 

                        if(ac_data[i]['userac_status']==1){ 
                            this.total_active_default_balance= this.total_active_default_balance+ ac_data[i]['default_currency_balance'];   
                        }else{
                            this.total_archive_default_balance= this.total_archive_default_balance+ ac_data[i]['default_currency_balance'];   
                        } 
                        total_d_balance = total_d_balance+ ac_data[i]['default_currency_balance'];

                        /*ac_data[i]['ac_percent']=(100*ac_data[i]['ac_balance']/response.data.accounts.at(-1).t_balance);*/  
                    } 
                    for (let i = 0; i < ac_data.length; i++) {  
                        ac_data[i]['ac_percent']=(100*ac_data[i]['default_currency_balance']/this.total_active_default_balance); 
                    } 
                    this.customer_accounts=ac_data;

                }
            }).catch(e => {
              console.log(e)
        });
    }
    
    show_myinvestment_trans(subac_id) { 
        this.doc_on = 0;
    }
    get_general_docs(ref_id,T_type,search) { 
        this.doc_on=1;
        Common.get_general_docs(this.local_storage['access-token'],ref_id,T_type,search)
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                    this.doc_on = 1;
                    this.docs= response.data.docs;   
                }else{
                    this.docs= [];  
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_user_info() { 
        Common.get_user_info(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    var user_inf=response.data.profile; 
                    user_inf.join_date=user_inf.join_date?moment(user_inf.join_date).format('DD MMM YYYY '):'';
                    this.user_info=user_inf;  
                    this.default_currency       =   user_inf.default_currency;
                    this.default_currency_icon  =   user_inf.default_currency_icon;
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_account_detail(ac_name) {   
        Common.get_account_detail(this.local_storage['access-token'],ac_name)
            .then(response => {
                this.subac=[]; 
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    // this.myinvestment = response.data.ac_info;
                    var myinvestment            = response.data.ac_info; 
                    myinvestment.userac_establish_date=moment(myinvestment.userac_establish_date).format('DD MMM, YYYY ');
                    this.myinvestment           = myinvestment;
                    this.subac              = response.data.ac_data.subaccounts;
                    this.selected_subac     = response.data.ac_data.subaccounts;


                    var s_data = this.subac;  
                    for (let i = 0; i < s_data.length; i++) { 
                        /*Date Convesation*/ 
                        /*Invested Date*/
                            if(typeof s_data[i].investment['trans_date']!='undefined'){
                                s_data[i].investment['trans_date']=moment(s_data[i].investment['trans_date']).format('DD MMM, YYYY '); 
                            }else{
                                s_data[i].investment['trans_date']='';
                            }  

                        /*Dividend Date*/  
                            var coupon_time = 0;
                            if(typeof s_data[i].investment['trans_date']!='undefined'){
                                if(s_data[i].myinvestment_coupon=='Monthly'){
                                    coupon_time = 1;
                                }else if(s_data[i].myinvestment_coupon=='Bi-Monthly'){
                                    coupon_time = 2;
                                }else if(s_data[i].myinvestment_coupon=='Semi annually'){
                                    coupon_time = 6;
                                }else if(s_data[i].myinvestment_coupon=='Annually'){
                                    coupon_time = 12;
                                }else{
                                    coupon_time = 1;
                                }    
                                var dividend_date = moment(s_data[i].investment['trans_date']).add(coupon_time,'months').format('DD MMM, YYYY ');  

                                s_data[i].dividend_date=dividend_date; 
                            }else{
                                s_data[i].dividend_date='';
                            }  

                        /*Maturity Date*/  
                            // $maturity_date = date('Y-m-d',strtotime(@$sv->investment['trans_date']. ' +'.(int)$sv->inv_term.' '.$matur)); 
                            var matur       = 'months';
                            var inv_period  = 'Months';
                            
                            if(s_data[i].myinvestment_period>1)  { 
                                if(s_data[i].myinvestment_period_type==1){
                                    inv_period = parseInt(s_data[i].myinvestment_period)+' Months';
                                    matur = 'months'; 
                                }else{
                                    inv_period = parseInt(s_data[i].myinvestment_period)+' Years';
                                    matur = 'years'; 
                                }   
                            } else{
                                if(s_data[i].inv_term_type==1){
                                    inv_period = parseInt(s_data[i].myinvestment_period)+' Month';
                                    matur = 'months'; 
                                }else{
                                    inv_period = parseInt(s_data[i].myinvestment_period)+' Year';
                                    matur = 'years'; 
                                } 
                            } 
                            if(typeof s_data[i].myinvestment_period!='undefined'){
                                s_data[i].investment_period = inv_period;
                            }

                            if(typeof s_data[i].investment['trans_date']!='undefined'){
                                var AACOUNT = parseInt(s_data[i].myinvestment_period); 
                                var maturity_date = '';
                                if(matur=='months'){
                                    maturity_date = moment(s_data[i].investment['trans_date']).add(AACOUNT,'months').format('DD MMM, YYYY ');   
                                }else{
                                    maturity_date = moment(s_data[i].investment['trans_date']).add(AACOUNT,'years').format('DD MMM, YYYY ');   
                                } 
                                s_data[i].maturity_date=maturity_date; 
                            }else{
                                s_data[i].maturity_date='';
                            }  


                        /*Investment Period*/
                        if(typeof s_data[i].investment['trans_date']!='undefined'){
                            
                            var ttt =moment(s_data[i].investment['trans_date']).format('DD MMM YYYY | HH:mm');

                            var AS_date = s_data[i].investment['trans_date'];
                            
                            var PPdate = moment(AS_date).utcOffset('+11').format();
                            var PPdate1:any = moment(PPdate).format("X");
                            var PPdate2:any = moment().unix();

                            var PPdiff   =Math.abs(PPdate2-PPdate1);

                            var Pyears  = Math.floor(PPdiff / (365*60*60*24));
                            var Pmonths = Math.floor((PPdiff - Pyears * 365*60*60*24) / (30*60*60*24));
                            var Pdays   = Math.floor((PPdiff - Pyears * 365*60*60*24 - Pmonths*30*60*60*24)/ (60*60*24)); 

                            s_data[i].t_days =Pdays;
                            s_data[i].t_months =Pmonths;
                            s_data[i].t_years =Pyears;

                            // s_data[i].investment_duration='';

                            // if(Pyears>0){
                            //     s_data[i].investment_duration = Pyears+ ((Pyears>1)? ' yrs' :' yr') 
                            // }
                            // else if(Pmonths>0){
                            //     s_data[i].investment_duration = Pmonths+ ((Pmonths>1)? ' months' :' month') 
                            // }
                            // else{
                            //     s_data[i].investment_duration = Pdays+ ((Pdays>1)? ' days' :' day') 
                            // } 

                            /*Overriding Existing previous conditions*/
                            s_data[i].investment_duration = s_data[i].investment_duration + ' Days'; 
                            
                            s_data[i].roi   = (s_data[i].investment['trans_amount']+s_data[i].myinvestment_growth+s_data[i].myinvestment_yield+s_data[i].subac_currency_investment)/s_data[i].trans_currency_total; 
                            s_data[i].irr   = s_data[i].roi/((Pyears>=1)?Pyears:1);
                        }else{
                            s_data[i].investment_duration ='';
                            s_data[i].roi ='';
                            s_data[i].irr = '';
                        }


                        /*Investment Return*/
                            // s_data[i].investment_return = 0;
                            // if(typeof s_data[i].investment['trans_amount']!='undefined'){ 
                            //     s_data[i].investment_return= (s_data[i].investment['trans_amount']*(s_data[i].myinvestment_yield/100))/s_data[i].inv_term;
                            // }

                        /*Investment Valuation*/
                            // s_data[i].investment_valuation = 0;
                            // if(typeof s_data[i].investment['trans_amount']!='undefined'){ 
                            //     s_data[i].investment_valuation= s_data[i].investment['trans_amount']+s_data[i].investment_return+s_data[i].myinvestment_growth;
                            // }

                       



                        var d_data = s_data[i].deposits;   
                        for (let j = 0; j < d_data.length; j++) { 
                            /*d_data[j]['t_time']=moment.unix(d_data[j]['trans_time']).utcOffset('+11').format('DD MMM YYYY | HH:mm');*/
                            d_data[j]['t_time']=moment(d_data[j]['trans_date']).format('DD MMM YYYY | HH:mm');
                        } 
                        s_data[i].deposits=d_data;

                        var w_data = s_data[i].withdrawls;   
                        for (let j = 0; j < w_data.length; j++) { 
                            /*w_data[j]['t_time']=moment.unix(w_data[j]['trans_time']).utcOffset('+11').format('DD MMM YYYY | HH:mm');*/
                            w_data[j]['t_time']=moment(w_data[j]['trans_date']).format('DD MMM YYYY | HH:mm');
                        } 
                        s_data[i].withdrawls=w_data;


                        var i_data = s_data[i].investments;   
                        for (let j = 0; j < i_data.length; j++) { 
                            /*i_data[j]['t_time']=moment.unix(i_data[j]['trans_time']).utcOffset('+11').format('DD MMM YYYY | HH:mm');*/
                            i_data[j]['t_time']=moment(i_data[j]['trans_date']).format('DD MMM YYYY | HH:mm');

                            var t_date = i_data[j].trans_date;
                            var date = moment(t_date).utcOffset('+11').format();
                            var date1:any = moment(date).format("X");
                            var date2:any = moment().unix();

                            var diff   =Math.abs(date2-date1);

                            var years  = Math.floor(diff / (365*60*60*24));
                            var months = Math.floor((diff - years * 365*60*60*24) / (30*60*60*24));
                            var days   = Math.floor((diff - years * 365*60*60*24 - months*30*60*60*24)/ (60*60*24)); 
 
                            i_data[j]['t_days']=days;
                            i_data[j]['t_months']=months;
                            i_data[j]['t_years']=years;

                            i_data[j]['investment_period']='';

                            if(years>0){
                                i_data[j]['investment_period'] = years+ ((years>1)? ' yrs' :' yr') 
                            }
                            else if(months>0){
                                i_data[j]['investment_period'] = months+ ((months>1)? ' months' :' month') 
                            }
                            else{
                                i_data[j]['investment_period'] = days+ ((days>1)? ' days' :' day') 
                            } 
                            i_data[j]['roi'] = (s_data[i].subac_currency_balance+s_data[i].myinvestment_growth+s_data[i].myinvestment_yield+s_data[i].subac_currency_investment)/i_data[j].trans_currency_total; 
                            i_data[j]['irr'] = i_data[j]['roi']/((years>=1)?years:1);



                        } 
                        s_data[i].investments=i_data;
                    } 
                    this.subac=this.selected_subac=s_data   

                    // console.log(this.subac);
                    
                    this.filterTrans(this.active_subac);
                    /*Chart Data*/
                        var chart_info =response.data.chart_data;  
                        this.chart1 = chart_info.last_month_chart;  
                        var new_series1 = [
                            {
                                name: "Balance",
                                data: this.chart1,
                            },
                        ]; 
                        this.series1=new_series1  

                        this.chart2 = chart_info.three_month_chart;
                        var new_series2 = [
                            {
                                name: "Balance",
                                data: this.chart2,
                            },
                        ]; 
                        this.series2=new_series2 

                        this.chart3 = chart_info['six_month_chart'];
                        var new_series3 = [
                            {
                                name: "Balance",
                                data: this.chart3,
                            },
                        ]; 
                        this.series3=new_series3  

                        this.chart4 = chart_info['year_chart'].reverse();
                        var new_series4 = [
                            {
                                name: "Balance",
                                data: this.chart4,
                            },
                        ]; 
                        this.series4=new_series4 
 
                         
                        this.chart5 = [];
                        var labelss :any= [];
                        if(chart_info['all_chart'].length==1){
                            this.chart5.push(0);
                            labelss.push((chart_info['all_chart'][0]['year']-1));
                        }
                        for (let i = 0; i < chart_info['all_chart'].length; i++) {  
                            this.chart5.push(chart_info['all_chart'][i]['balance']);
                            labelss.push(chart_info['all_chart'][i]['year']);
                        }
                        var newchartOptions5= {
                            chart: {
                                id: "vuechart-example",
                                type: "line",
                                height: 180,
                                foreColor: '#494949',
                                toolbar: {
                                    show: false
                                },
                                stacked:true
                            },
                            xaxis: {
                                categories: labelss,
                            }, 
                            yaxis: { 
                                axisBorder: {
                                    show: false
                                },
                                axisTicks: {
                                    show: false,
                                }, 
                                labels: {
                                    show: false,
                                    formatter: function (val) {
                                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                                    }
                                },
                                min: 0,
                                forceNiceScale: true,

                            },
                            plotOptions: {
                                bar: {
                                    borderRadius: 4,
                                    columnWidth: '35%',
                                    dataLabels: {
                                        position: 'top', 
                                    },
                                }
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (val) {
                                    return "$" + val;
                                },
                                offsetY: -20,
                                style: {
                                    fontSize: '12px',
                                    colors: ['#FFF']
                                }
                            }, 
                            colors: ['#494949'], 
                            grid: {
                                show: false,
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },  
                            }, 
                        }; 
 
                        var new_series5 = [
                            {
                                name: "Balance",
                                data: this.chart5,
                            },
                        ]; 
                        this.chartOptions5=newchartOptions5; 
                        this.series5=new_series5;
                        this.on_chart=1 ;
                    /*Chart End*/
                } 
            }).catch(e => {
              console.log(e)
        }); 
    } 

    filterTrans(subac_id){    
         
        // var subac_id = event.target.value; 
        if(typeof subac_id != "undefined" && subac_id>0){
            localStorage.setItem(
                'selected_myinvestment_subac_id', subac_id
            ); 
            this.active_subac = subac_id
            var f_data:any =[];
            var t_data:any =this.subac;
            for (let i = 0; i < t_data.length; i++) { 
                if(t_data[i]['subac_id']==subac_id){
                    f_data[i]=t_data[i]; 
                } 
            } 
            f_data = f_data.filter(value => Object.keys(value).length !== 0); 
            this.selected_subac=f_data; 
        }
    }



    async get_myinvestments(){ 
        await Common.get_myinvestments(this.local_storage['access-token'])
            .then(response => { 
                this.myinvestments = response.data.myinvestments;  
            }).catch(e => {
              console.log(e)
        });
    } 
    change_myinv(myinv_id,tab){   

        localStorage.setItem('selected_myinv_id',myinv_id); 
        localStorage.setItem('selected_myinvestment_tab',tab);  
        this.active_myinv_id    =   myinv_id;
        this.active_tab         =   tab;  
        if(typeof tab=='undefined' || tab==null || tab=='' || typeof myinv_id=='undefined' || myinv_id==null || myinv_id==''){ 
            this.active_tab     =   '';             
        } 

        // this.get_myinv_basic_info(); 
    } 
    beforeCreate(){
        this.load_on=1;
    }  
    data () {        
        return {  
            customer_accounts               :this.customer_accounts, 
            user_info                       :this.user_info,   
            default_currency_icon           :this.default_currency_icon,
            total_active_default_balance    :this.total_active_default_balance,

            active_myinv_id     : this.active_myinv_id,
            active_tab          : this.active_tab, 
            load_on             : this.load_on
        } 
    }  
 


  }
