
    import { Options, Vue } from 'vue-class-component'; 
    import Basic from '@/components/customer/Basic.vue';
    import Rightside from '@/components/customer/Rightside.vue';
    import Common from '@/services/common.js'; 
    import moment from 'moment';

    import Toast from '@/components/Toast.vue';    
    import Beneficiary from '@/components/customer/Beneficiary.vue'; 
    import Axios from "axios";   


 
  @Options({
    components: {
        'toasti':Toast,  
        'beneficiary':Beneficiary, 
        'right-side':Rightside,
        Basic
    },
  })
  export default class ManagingMembers extends Vue { 
    STORAGE_KEY = "caprock-local";   
    user_name = ''; 
    user_info = {}; 
    load_on:any=0;
    token='';
    customer_accounts   =   []; 
    total_ac_balance    =   0; 
    directors :any =  [];
    local_storage:any;
    dashboard = {
        currencies:[]
    }; 

    countries       :any    =   [];
    beneficiary_id:any=null;
    beneficiary_on:any=0; 

    timer :any= undefined;
    
    data () {        
        return {  
            load_on             :   this.load_on, 
            customer_accounts   :   this.customer_accounts,
            user_info           :   this.user_info ,

            beneficiary_on      :   this.beneficiary_on,
            beneficiary_id      :   this.beneficiary_id,
            countries           :   this.countries,  
            reload_b            :   1,  
        } 
    }   
    

    beforeCreate(){
        this.load_on=1;
    } 


    country_list(){
        Common.get_all_countries(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 1) {  
                    var countries   =   response.data.data.countries;
                    this.countries  = countries;  
                }
            }).catch(e => {
              console.log(e)
        });
    }
    created() {     
        var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
        if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
            // console.log('My directors');
            this.token=js['access-token'];
        }else{  
            localStorage.removeItem(this.STORAGE_KEY); 
            this.$router.push('/sign-in'); 
        } 
        document.title = "Managing Members || Caprock Merchant Platform";
        localStorage.setItem(
            'active_page', 'members'
        ); 
    }
    async mounted(){
        this.user_name=''; 
        this.get_user_accounts(); 
        this.get_user_info(); 
        await this.get_members('');  
        this.country_list();
        setTimeout(() => this.load_on=0, 500); 
    }
    open_link(id){
        var link = (document.getElementById("URL"+id) as HTMLInputElement).value; 
        window.open(link,'_blank');
    }

    get_user_accounts() { 
        Common.get_user_accounts(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                    this.customer_accounts=response.data.accounts;
                    this.total_ac_balance   =   response.data.t_balance.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) ;  
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_user_info() { 
        Common.get_user_info(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    var user_inf=response.data.profile; 
                    user_inf.join_date=user_inf.join_date?moment(user_inf.join_date).format('DD MMM YYYY '):'';
                    this.user_info=user_inf;
                }
            }).catch(e => {
              console.log(e)
        });
    }
    async get_members(search) {   
        await Common.get_members(this.local_storage['access-token'],search)
            .then(response => {
                this.directors=[];  
                if (typeof response.data !== 'undefined' && response.data.success == 200) {    
                    var sdocs =response.data.directors; 
                    for (let i = 0; i < sdocs.length; i++) {                           
                        sdocs[i]['no'] =i+1; 
                    } 
                    this.directors=sdocs;
                }
            }).catch(e => {
              console.log(e)
        });
    } 
    view_beneficiary(id){  
        if(typeof id!='undefined'){ 
            this.beneficiary_on=1;
            this.beneficiary_id=id;  
            (this.$refs['bnF'] as any).openModal('beneficiary_details');
            (this.$refs['bnF'] as any).get_member_info(this.local_storage['access-token'],id); 
        } 
    } 
    get_member_info(id){ 
        Common.get_member_info(this.local_storage['access-token'],id)
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    // var beneficiary         =   response.data.beneficiary;  
                    // this.beneficiary_info   =   beneficiary;   
                }
            }).catch(e => {
              console.log(e)
        });
    }
    textSearch(e:any){
        clearTimeout(this.timer) 
        this.timer = setTimeout(() => {
            this.get_members(e.target.value)
             
        }, 300)
    }
 
     
 
  }
