
  import { Options, Vue } from 'vue-class-component';
  import MyinvFeesBasic from '@/components/myinvestments/MyinvFeesBasic.vue';
  import Common from '@/services/common.js';
  import moment from 'moment';  
   
  @Options({
    
    props: {
      active_tab:       String,
      myinv_id:         Number, 
      doc_type:         String, 
    },

    components: {  
      'basic' : MyinvFeesBasic,   
    },


  })
  export default class MyinvInterest extends Vue {
    active_tab;  
    myinv_id; 
    local_storage :any= {};


    fees          :any =  [];  
    myinvestment  :any;

    docs :any =  []; 
    doc_type  ; 
    timer :any= undefined;

    
    data () {      
      return {  
        active_tab:this.active_tab,
        myinv_id:this.myinv_id, 
        myinvestment:this.myinvestment,  
        fees:this.fees,  
      } 
    }    

    created() {    
      this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));   
      return true;
    }

    mounted() {
      this.myinv_id ;
      this.active_tab; 
      this.fees;  
      this.myinvestment;       
      this.get_interest_payments();  
    }  
 


    get_interest_payments(){ 
      Common.get_myinvestment_interest_payments(this.local_storage['access-token'],this.myinv_id)
          .then(response => {
              console.log(response.data); 
              if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                  var fees          = response.data.fees; 
                  var myinvestment  = response.data.investment;

                  for (let i = 0; i < fees.length; i++) {  
                    fees[i]['total_due_fee']= fees[i]['interest_merchant_fee']+fees[i]['interest_principle_fee'];


                    var paid_fees = 0;
                    if(typeof (fees[i]['interest_merchant_fee_trans_id']) !=='undefined' && fees[i]['interest_merchant_fee_trans_id']>0){
                      paid_fees = paid_fees + fees[i]['interest_merchant_fee'];
                    }
                    if(typeof (fees[i]['interest_principle_fee_trans_id']) !=='undefined' && fees[i]['interest_principle_fee_trans_id']>0){
                      paid_fees = paid_fees + fees[i]['interest_principle_fee'];
                    } 
                    fees[i]['total_paid_fee']= paid_fees;
                    


                    fees[i]['interest_date']    =   moment(fees[i]['interest_date']).format('DD-MM-YYYY');
                      
                    fees[i]['interest_amount']=   myinvestment['currency_symbol'] +' '+ (typeof fees[i]['interest_amount']!=='undefined' ? fees[i]['interest_amount'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);

                    fees[i]['interest_paid']=   myinvestment['currency_symbol'] +' '+ (typeof fees[i]['interest_paid']!=='undefined' ? fees[i]['interest_paid'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);

                    fees[i]['interest_balance']=   myinvestment['currency_symbol'] +' '+ (typeof fees[i]['interest_balance']!=='undefined' ? fees[i]['interest_balance'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);

                    fees[i]['interest_merchant_fee']=   myinvestment['currency_symbol'] +' '+ (typeof fees[i]['interest_merchant_fee']!=='undefined' ? fees[i]['interest_merchant_fee'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);

                    fees[i]['interest_principle_fee']=   myinvestment['currency_symbol'] +' '+ (typeof fees[i]['interest_principle_fee']!=='undefined' ? fees[i]['interest_principle_fee'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);

                    fees[i]['interest_fee_balance']=   myinvestment['currency_symbol'] +' '+ (typeof fees[i]['interest_fee_balance']!=='undefined' ? fees[i]['interest_fee_balance'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);

                    fees[i]['total_due_fee']=   myinvestment['currency_symbol'] +' '+ (typeof fees[i]['total_due_fee']!=='undefined' ? fees[i]['total_due_fee'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);
                    fees[i]['total_paid_fee']=   myinvestment['currency_symbol'] +' '+ (typeof fees[i]['total_paid_fee']!=='undefined' ? fees[i]['total_paid_fee'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);
                    
                    // fees[i]['principle_paid_amount']    =   myinvestment['currency_symbol'] +' '+ (typeof fees[i]['principle_paid_amount']!=='undefined' ? fees[i]['principle_paid_amount'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);
                    
                    // fees[i]['principle_amount']         =   myinvestment['currency_symbol'] +' '+ (typeof fees[i]['principle_amount']!=='undefined' ? fees[i]['principle_amount'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);   
 
                  }  
                  this.fees         = fees; 
                  this.myinvestment = myinvestment; 
              }
          }).catch(e => {
            console.log(e)
      });
    } 
 

  }
