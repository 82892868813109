
  import { Options, Vue } from 'vue-class-component';  
  import AcquisitionBasic from '@/components/securities/AcquisitionBasic.vue';
  import Common from '@/services/common.js';
  import moment from 'moment';
   
   
  @Options({
    props: { 
      fum_id:     Number, 
    },

    components: {  
      'acq_basic' : AcquisitionBasic, 
    },

  })
  export default class SecuritiesAcquisition extends Vue { 
    fum_id;
    local_storage:any; 
    transactions:any=[]; 
    acquisitions:any=[];  
    basic:any={}; 

    data () {       
      return {   
        fum_id:this.fum_id, 
        basic   :this.basic, 
      }
 
    }    
    created() {
      this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));

      return true;
    }
    mounted() {
      this.fum_id    
      this.get_securities_acqusition_basic();
    }   

    get_securities_acqusition_basic() { 
      Common.get_securities_acqusition_basic(this.local_storage['access-token'],this.fum_id)
          .then(response => {
              if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                this.basic          = response.data.basic; 
                
                /*Acquisitions*/
                  var acquisitions = response.data.basic.acquisitions;  
                  for (let i = 0; i < acquisitions.length; i++) { 
                    acquisitions[i]['acq_date']     = moment(acquisitions[i]['acq_date']).format('DD/MM/YYYY'); 

                    acquisitions[i]['acq_balance']  = acquisitions[i]['acq_total_credit']-acquisitions[i]['acq_total_debit'];
                    acquisitions[i]['acq_profit']   = acquisitions[i]['acq_total_credit']-acquisitions[i]['acq_total_debit']; 
     
                  }   
                  this.acquisitions   = acquisitions; 
                /*END ACQ*/ 

                /*Acquisitions Transactions*/
                  var transactions = response.data.basic.transactions; 
                  var return_balance = 0;
                  for (let i = 0; i < transactions.length; i++) { 
                    transactions[i]['trans_date']   =moment(transactions[i]['trans_date']).format('DD-MM-YYYY'); 

                    if(transactions[i]['ref_trans_type']==2){
                      return_balance = return_balance +transactions[i]['trans_amount']; 
                    }else{
                      return_balance = return_balance -transactions[i]['trans_amount']; 
                    } 
                    transactions[i]['trans_balance']=return_balance; 
                  }  
                  this.transactions=transactions;  
                /*END TRANSACTIONS*/

 
              }
          }).catch(e => {
            console.log(e)
      });
    }


  }
