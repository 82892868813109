
  import { Options, Vue } from 'vue-class-component'; 
  import Common from '@/services/common.js';
  import moment from 'moment';
   
  @Options({
    props: { 
        fum_id: 		Number, 
    }
  })
  export default class SecuritiesFees extends Vue { 
    fum_id;
    local_storage:any; 
    fees:any=[];   
    data () {      
      return {   
        fum_id:this.fum_id, 
        fees:this.fees, 
      } 
    }    
    created() {
      this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}")); 
      return true;
    }
    mounted() {
      this.fum_id    
      this.get_securities_fees();
    } 
    get_securities_fees() { 
      Common.get_securities_fees(this.local_storage['access-token'],this.fum_id)
          .then(response => {
              if (typeof response.data !== 'undefined' && response.data.success == 200) {   
                /*Fees*/
                  var fees      =   response.data.fees;  
                  var last_date =   '';
                  var i         =   0;


                  for (let i = 0; i < fees.length; i++) { 
                    if(last_date!=fees[i]['fee_date'] && i>0 ) {
                      fees[i]['fee_border']   = 1;   
                    }  
                    fees[i]['fee_date']     = moment(fees[i]['fee_date']).format('DD/MM/YYYY');  
                    fees[i]['fee_balance']  = fees[i]['fee_inc_gst_amount']-fees[i]['fee_paid_amount'];  
 

                    last_date = fees[i]['fee_date'];
                    i++;
                  }   
                  this.fees   = fees; 
                /*END Fees*/  
              }
          }).catch(e => {
            console.log(e)
      });
    } 
  }
