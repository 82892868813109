
    import { Options, Vue } from 'vue-class-component'; 
    import Basic from '@/components/customer/Basic.vue';
    import Rightside from '@/components/customer/Rightside.vue'; 
    import Common from '@/services/common.js';
    import moment from 'moment'; 
    import Portfolio_card from '@/components/accounts/Portfolio_card.vue';
 
 
  @Options({
    components: { 
        'right-side':Rightside, 
        'portfolio-card': Portfolio_card,
        Basic 
    },
  })
  export default class Paymaster extends Vue { 
    STORAGE_KEY = "caprock-local";   
    user_name = ''; 
    user_info = {}; 
    customer_accounts       =   []; 
    total_ac_balance        =   0;  
    total_active_default_balance    =   0;  
    total_archive_default_balance   =   0;  
    default_currency_icon           =   '$';  
    default_currency                =   'AUD';  
    subac :any =  [];
    cashac_fees :any=[];
    transactions :any =  [];
    all_transactions :any =  [];
    active_subac :any = '';
    local_storage:any;
    paymaster = {
        ac_balance                  :0,
        default_currency_balance    :0,
        userac_name                 :'Cash Account',
        userac_reference_code       :'#',
        userac_establish_date       :''
    };  

    timer :any= undefined; 
    
    created() {     
        var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
        if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
            /*console.log('Cash Account');*/
        }else{ 
            localStorage.removeItem(this.STORAGE_KEY); 
            this.$router.push('/sign-in'); 
        } 
        document.title = "Cash Account || Caprock Merchant Platform";
        localStorage.setItem(
            'active_page', 'paymaster'
        ); 
    }
    mounted(){
        this.user_name=''; 
        this.get_user_accounts(); 
        this.get_user_info(); 
        this.get_account_detail('paymaster');  
    }
    open_link(id){
        var link = (document.getElementById("URL"+id) as HTMLInputElement).value; 
        window.open(link,'_blank');
    }

    get_user_accounts() { 
        Common.get_user_accounts(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                    this.total_ac_balance   =   response.data.t_balance.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) ;   
                    this.total_active_default_balance=0;
                    this.total_archive_default_balance=0;
                    var ac_data = response.data.accounts; 
                    for (let i = 0; i < ac_data.length; i++) { 
                        /*ac_data[i]['ac_percent']=(100*ac_data[i]['ac_balance']/response.data.accounts.at(-1).t_balance);*/

                        if(ac_data[i]['userac_status']==1){ 
                            this.total_active_default_balance= this.total_active_default_balance+ ac_data[i]['default_currency_balance'];   
                        }else{
                            this.total_archive_default_balance= this.total_archive_default_balance+ ac_data[i]['default_currency_balance'];   
                        }
                    } 
                    for (let i = 0; i < ac_data.length; i++) {  
                        ac_data[i]['ac_percent']=(100*ac_data[i]['default_currency_balance']/this.total_active_default_balance); 
                    } 
                    this.customer_accounts=ac_data;
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_user_info() { 
        Common.get_user_info(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    var user_inf=response.data.profile; 
                    user_inf.join_date=user_inf.join_date?moment(user_inf.join_date).format('DD MMM, YYYY YYYY '):'';
                    this.user_info=user_inf; 

                    this.default_currency       =   user_inf.default_currency;
                    this.default_currency_icon  =   user_inf.default_currency_icon;
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_account_detail(ac_name) {   
        Common.get_account_detail(this.local_storage['access-token'],ac_name)
            .then(response => {
                this.subac=[]; 
                if (typeof response.data !== 'undefined' && response.data.success == 200) {   
                    var paymasterdata = response.data.ac_info; 

                    paymasterdata.userac_establish_date     =   moment(paymasterdata.userac_establish_date).format('DD MMM, YYYY ');
                    this.paymaster                          =   paymasterdata;
                    
                    this.subac = response.data.ac_data.subaccounts; 
                    

                    var subII:any = localStorage.getItem('cashac_fee_subac_id'); 
                    var subId =''; 
                    if(typeof subII!=='undefined' && subII>0){
                        subId =subII;
                    }else{
                        subId = response.data.ac_data.subaccounts.at(-1).subac_id;
                    }
                    this.active_subac=subId; 
                    this.get_cashac_fees(this.active_subac);
                } 
            }).catch(e => {
              console.log(e)
        });
    }


    get_cashac_fees(subac_id){ 
        if(typeof subac_id != "undefined" && subac_id>0){
            localStorage.setItem(
                'cashac_fee_subac_id', subac_id
            ); 
            this.active_subac = subac_id

            Common.get_cashac_fees(this.local_storage['access-token'],subac_id)
                .then(response => {
                    this.cashac_fees    =[]; 
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {   
                        var cashac_fees = response.data.fees;   
                        for (let i = 0; i < cashac_fees.length; i++) { 
                            cashac_fees[i]['f_time']=moment(cashac_fees[i]['fee_date']).format('DD MMM, YYYY'); 
                        }  
                        this.cashac_fees=cashac_fees;  
                    } 
                }).catch(e => {
                console.log(e)
            }); 
        }   
    }


    filterTrans(event:any)
        {      
            var subac_id = event.target.value;
            if(typeof subac_id != "undefined" && subac_id>0){
                localStorage.setItem(
                    'cashac_fee_subac_id', subac_id
                ); 
                this.active_subac = subac_id 
                this.get_cashac_fees(subac_id); 
            }
        }
 
    data () {        
        return {  
            customer_accounts:this.customer_accounts,
            user_info:this.user_info,
            default_currency_icon:this.default_currency_icon,
            total_active_default_balance:this.total_active_default_balance,
        } 
    }   

  }
