
    import { Options, Vue } from 'vue-class-component';
    import Common from '@/services/common.js';
    import moment from 'moment';  

    @Options({ 
        props: {
            fum_id:     Number,  
        },  
    })
    
    export default class SecuritiesSecurities extends Vue { 
      
        fum_id;
        local_storage:any; 
        securities:any=[]; 
        basic:any={}; 
        data () {      
          return {    
            fum_id    :this.fum_id, 
            basic     :this.basic, 
            securities:this.securities, 
          } 
        }   

       
        created() {    
            this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));    
            return true;
        }
        mounted() {
          this.fum_id;     
          // this.get_basic_info();
          this.get_fum_securities();
        }  

        get_basic_info() { 
          Common.get_basic_info(this.local_storage['access-token'],this.fum_id)
              .then(response => {
                  if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                      this.basic = response.data.basic; 
                  }
              }).catch(e => {
                console.log(e)
          });
        }
        get_fum_securities() { 
          Common.get_fum_securities(this.local_storage['access-token'],this.fum_id)
              .then(response => {
                  if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    var securities = response.data.securities;  
                    for (let i = 0; i < securities.length; i++) { 
                      securities[i]['investor_visible']=0;   
                      securities[i]['investment_publish_date']=moment(securities[i]['investment_publish_date']).format('DD MMM,YYYY');  
                      

                      if(securities[i]['inv_type']==4){
                        securities[i]['inv_type']='In-House Strategies';
                      }else if(securities[i]['inv_type']==5){
                        securities[i]['inv_type']='Outsourced Strategies';
                      }else if(securities[i]['inv_type']==6){
                        securities[i]['inv_type']='Private Label';
                      }else if(securities[i]['inv_type']==7){
                        securities[i]['inv_type']='Managed Investment Schemes';
                      }else{
                        securities[i]['inv_type']='';
                      } 
 
                      if(typeof securities[i]['investors']!=='undefined'){
                        for (let j = 0; j < securities[i]['investors'].length; j++) {  
                          securities[i]['investors'][j]['investor_request_date']=moment(securities[i]['investors'][j]['investor_request_date']).format('DD/MM/YYYY');  
                          securities[i]['investors'][j]['investor_inv_amount']=parseFloat(securities[i]['investors'][j]['investor_inv_amount']).toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}); 
                        }        
                      } 
                    }    
                    this.securities = securities; 
                  }
              }).catch(e => {
                console.log(e)
          });
        }

        investor_visible(status,security_id){
          var securities = this.securities;
          for (let i = 0; i < securities.length; i++) { 
            if(securities[i]['security_id']==security_id){
              securities[i]['investor_visible']=status;
            }
          }   
        }
    }
