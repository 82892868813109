
  import { Options, Vue } from 'vue-class-component';
   
  @Options({
    props: {
        active_tab:		String,
        fum_id: 		Number, 
    }
  })
  export default class SecuritiesHeader extends Vue {
    active_tab  
    fum_id 
    data () {     
      
      return {  
        active_tab:this.active_tab,
        fum_id:this.fum_id, 
      }
 
    }    
    created() {       
      return true;
    }
    mounted() {
        this.fum_id 
        this.active_tab    
    }  
  }
