
    import { Options, Vue } from 'vue-class-component';
    import HelloWorld from '@/components/HelloWorld.vue';
    import Basic from '@/components/customer/Basic.vue';
    import Common from '@/services/common.js';
    import moment from 'moment'; 
    import VueApexCharts from "vue3-apexcharts";
 
 
    @Options({
        components: {
            HelloWorld, 
            Basic,
            'apexchart': VueApexCharts 
        },
    })
    export default class Currency extends Vue { 
        STORAGE_KEY = "caprock-local";   
        user_name = ''; 
        customer_accounts   =   []; 
        total_ac_balance    =   0;  
        local_storage:any;
        dashboard = {
            currencies:[]
        };
        alerts:any = [];

        year_chart_values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        
        this_month = moment().format("MMM, YYYY");
        prev_month = moment().subtract(1, 'months').startOf('month').format("MMM, YYYY");
         
        chartOptions= {
            chart: {
                id: "vuechart-example",
                type: "bar",
                height: 180,
                foreColor: '#85addb',
                toolbar: {
                    show: false
                },
                stacked:true
            },
            xaxis: {
                categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            }, 
            yaxis: { 
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                    formatter: function (val) {
                        return "$" + val.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2});
                    }
                },
                min: 0,
                forceNiceScale: true,

            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    columnWidth: '35%',
                    dataLabels: {
                        position: 'top', 
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return "$" + val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ['#FFF']
                }
            }, 
            colors: ['#FF6633'], 
            grid: {
                show: false,
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0
                },  
            }, 
        };
        series = [
            {
                name: "Balance",
                data: this.year_chart_values,
            },
        ];
        
        created() {     
          var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
          if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
            /*console.log('Try After Sometime');*/
          }else{ 
            localStorage.removeItem(this.STORAGE_KEY); 
            this.$router.push('/sign-in'); 
          } 
          document.title = "Currency || Caprock Merchant Platform";
        }
        mounted(){
            this.user_name=''; 
            this.get_user_accounts();
            this.get_dashboard();  
        }

        get_user_accounts() { 
            Common.get_user_accounts(this.local_storage['access-token'],1)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        var c_acs =response.data.accounts;
                        this.total_ac_balance= response.data.t_balance.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) ;    

                        for (let i = 0; i < c_acs.length; i++) {  
                            var ser= [{
                                name: 'Balance', 
                                data:c_acs[i]['ac_chart']
                            }];
                            var chartO= {
                                chart: {
                                    height: 200,
                                    type: 'area',
                                    toolbar: {
                                        show: false
                                    }
                                }, 
                                labels: {
                                    show: false,
                                },
                                grid: {
                                    show: false,
                                },
                                stroke: {
                                    curve: 'smooth',
                                    width: 2
                                },
                                xaxis: {  
                                    categories: moment.monthsShort(),
                                    labels: {
                                        show: false 
                                    } ,
                                    lines: {
                                        show: false
                                    } 
                                },
                                yaxis: { 
                                    axisBorder: {
                                        show: false
                                    },
                                    axisTicks: {
                                        show: false,
                                    }, 
                                    labels: {
                                        show: false 
                                    }  
                                },
                                colors: [c_acs[i]['ac_color']],
                                dataLabels: {
                                    enabled: false
                                }  
                            }; 

                            c_acs[i]['series']=ser;
                            c_acs[i]['chartOptions']=chartO;
                        } 
                        this.customer_accounts=c_acs;
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        get_dashboard() { 
            Common.get_dashboard(this.local_storage['access-token'])
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        this.dashboard=response.data.dashboard; 
                    }
                }).catch(e => {
                  console.log(e)
            });
        }

 


    }
