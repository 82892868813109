
    import { Options, Vue } from 'vue-class-component'; 
    import Basic from '@/components/customer/Basic.vue';
    import Rightside from '@/components/customer/Rightside.vue';
    import Common from '@/services/common.js';
    import moment from 'moment';
  // import LineChart from '@/components/chart/LineChart.js'

 
 
  @Options({
    components: { 
        'right-side':Rightside,
        Basic

    },
  })
  export default class Bankaccounts extends Vue { 
    STORAGE_KEY = "caprock-local";   
    user_name = ''; 
    user_info = {}; 
    bank_accounts       =   []; 
    customer_accounts   =   []; 
    total_ac_balance    =   0; 
    // datacollection =  {};
    local_storage:any;
    dashboard = {
        currencies:[]
    };
    alerts:any = [];
    

    data () {        
        return {  
            bank_accounts       :this.bank_accounts,
            customer_accounts   :this.customer_accounts,
            user_info           :this.user_info   
        } 
    } 
        
    created() {     
        var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
        if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
            /*console.log('Try After Sometime');*/
        }else{ 
            localStorage.removeItem(this.STORAGE_KEY); 
            this.$router.push('/sign-in'); 
        } 
        document.title = "My Accounts || Caprock Merchant Platform";
        localStorage.setItem(
            'active_page', 'profile'
        ); 
    }
    mounted(){
        this.user_name=''; 
        this.get_user_info(); 
        this.get_user_accounts(); 
        this.get_bank_accounts();  
    }

    get_bank_accounts() { 
        Common.app_bank_accounts(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    this.bank_accounts=response.data.bank_accounts; 
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_user_accounts() { 
        Common.get_user_accounts(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                    this.customer_accounts=response.data.accounts;
                    this.total_ac_balance   =   response.data.t_balance.toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) ;  
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_user_info() { 
        Common.get_user_info(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    var user_inf=response.data.profile; 
                    user_inf.join_date=user_inf.join_date?moment(user_inf.join_date).format('DD MMM YYYY '):'';
                    this.user_info=user_inf;
                    // console.log(this.user_info)
                    
                }
            }).catch(e => {
              console.log(e)
        });
    }
    get_dashboard() { 
        Common.get_dashboard(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                    this.dashboard=response.data.dashboard; 
                }
            }).catch(e => {
              console.log(e)
        });
    }


    // fillData () {
    //     this.datacollection = {
    //         labels: [this.getRandomInt(), this.getRandomInt()],
    //         datasets: [
    //             {
    //               label: 'Data One',
    //               backgroundColor: '#f87979',
    //               data: [this.getRandomInt(), this.getRandomInt()]
    //             }, {
    //               label: 'Data One',
    //               backgroundColor: '#f87979',
    //               data: [this.getRandomInt(), this.getRandomInt()]
    //             }
    //         ]
    //     }
    // }
    // getRandomInt () {
    //     return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    // }



  }
