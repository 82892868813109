
  import { Options, Vue } from 'vue-class-component'; 

  import Loader from '@/components/Loader.vue';  
  @Options({
    props: {
      msg: String,
      load_on: Number,  
    },
    components: {  
      'loader':Loader,
    }  
  })
  export default class Shareholderbasic extends Vue {
    msg!: string
    STORAGE_KEY   =   "caprock-local";   
    
    amad:any    =   false;
    token :any  =   false;

    data () {    
      if (typeof this.$route.query.token!='undefined' && this.$route.query.token!='' && this.$route.query.token!=null && typeof this.$route.query.amad!='undefined' && this.$route.query.amad!='' && this.$route.query.amad!=null) {
        this.amad = this.$route.query.amad;
        this.token = this.$route.query.token; 
      } else{
        alert('Invalid URL please contact caprock team');
      }
      if(!this.token || !this.amad){   
        this.logout();
      }  
      return {  
        token:this.token,
        amad:this.amad,
      }
    }    
    created() {    
      return true;
    }
    logout(){
      localStorage.clear(); 
      this.token='';   
      alert('Invalid URL please contact caprock team');
      // location.href='/sign-in'; 
    } 
  }
