
  import { Options, Vue } from 'vue-class-component';
  import MyinvBasic from '@/components/myinvestments/MyinvBasic.vue';
  import Common from '@/services/common.js';
  import moment from 'moment';  
   
  @Options({
    
    props: {
      active_tab:		String,
      myinv_id: 		Number, 
    },

    components: {  
      'basic' : MyinvBasic,   
    },


  })
  export default class MyinvInvestment extends Vue {
    active_tab;  
    myinv_id; 

    local_storage :any;
    principles :any=[];
    transactions : any=[];

    myinvestment:any;


    
    data () {      
      return {  
        active_tab:this.active_tab,
        myinv_id:this.myinv_id, 

        myinvestment  : this.myinvestment,
        principles    : this.principles,
        transactions  : this.transactions

      } 
    }    

    created() {   
      this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));        
      return true;
    }

    mounted() {
      this.myinv_id; 
      this.active_tab;    
      this.myinvestment;    
      this.get_investment_basic();

    }  

    get_investment_basic(){
    
      Common.get_myinvestment_investment(this.local_storage['access-token'],this.myinv_id)
          .then(response => {
              console.log(response.data); 
              if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                  var principles    = response.data.principles;
                  var transactions  = response.data.transactions;
                  var myinvestment  = response.data.investment;

                  for (let i = 0; i < principles.length; i++) {                           
                    principles[i]['principle_start_date'] =   moment(principles[i]['principle_start_date']).format('DD-MM-YYYY');
                    principles[i]['maturity_date']        =   moment(myinvestment['myinvestment_maturity_date']).format('DD-MM-YYYY');  
                    
                    principles[i]['principle_invested_amount']=   myinvestment['currency_symbol'] +' '+ (typeof principles[i]['principle_invested_amount']!=='undefined' ? principles[i]['principle_invested_amount'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);
                    
                    principles[i]['principle_paid_amount']    =   myinvestment['currency_symbol'] +' '+ (typeof principles[i]['principle_paid_amount']!=='undefined' ? principles[i]['principle_paid_amount'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);
                    
                    principles[i]['principle_amount']         =   myinvestment['currency_symbol'] +' '+ (typeof principles[i]['principle_amount']!=='undefined' ? principles[i]['principle_amount'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);   
 
                  } 



                  for (let i = 0; i < transactions.length; i++) {                           
                    transactions[i]['trans_date'] =moment(transactions[i]['trans_date']).format('DD-MM-YYYY'); 

                    
                    if(transactions[i]['trans_type']==2){
                      transactions[i]['trans_credit'] = transactions[i]['currency_symbol'] +' '+ (typeof transactions[i]['trans_amount']!=='undefined' ? transactions[i]['trans_amount'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);  
                      transactions[i]['trans_debit']  = '-';   
                    }else{
                      
                      transactions[i]['trans_debit'] = transactions[i]['currency_symbol'] +' '+ (typeof transactions[i]['trans_amount']!=='undefined' ? transactions[i]['trans_amount'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);   

                      transactions[i]['trans_credit'] = '-';   
                    } 
                  } 

                  /*basic['investment_valuation'] =  basic['currency_symbol'] + (typeof basic['investment_valuation']!=='undefined' ? basic['investment_valuation'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0); 
                  basic['yield'] =  (typeof basic['yield']!=='undefined' ? basic['yield'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0)+' %'; 
                  basic['dividend_next_paid']     = moment(basic['dividend_next_paid']).format('DD/MM/YYYY');*/  
                    

                  this.principles   = principles; 
                  this.transactions = transactions; 
                  this.myinvestment = myinvestment; 
              }
          }).catch(e => {
            console.log(e)
      });
    }


  }
