
  import { Options, Vue } from 'vue-class-component'; 
  import SecuritiesBasic from '@/components/securities/SecuritiesBasic.vue';
  import Common from '@/services/common.js';
  import moment from 'moment';  
   
   
  @Options({
    props: { 
      fum_id: 		Number, 
    },

    components: {  
      'basic' : SecuritiesBasic,  
    },

  })
  export default class SecuritiesFum extends Vue {  
    fum_id;
    local_storage:any; 
    transactions:any=[];  


    data () {      
      return {   
        fum_id:this.fum_id,  
      }
 
    }    

    
    created() { 
      this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));
      return true;
    }
    
    mounted() {
        this.fum_id;   
        this.get_securities_fum(); 
    }  
    get_securities_fum() { 
        Common.get_securities_fum(this.local_storage['access-token'],this.fum_id)
          .then(response => {
            if (typeof response.data !== 'undefined' && response.data.success == 200) {          
              var transactions = response.data.transactions; 
              for (let i = 0; i < transactions.length; i++) { 
                transactions[i]['trans_date']=moment(transactions[i]['trans_date']).format('DD-MM-YYYY'); 
              }  
              this.transactions=transactions;   
            }
          }).catch(e => {
            console.log(e)
        });
    }
  }
