import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35f1af48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-md-12 px-1 py-3 border-bottom" }
const _hoisted_2 = { class: "w-100 text-center bg-dark font-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        class: _normalizeClass([{ activeT: _ctx.active_tab== 'investment'}, "myTabs"]),
        href: "#",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$emit('myinv_change',_ctx.myinv_id,'investment')))
      }, "Investment", 2),
      _createTextVNode(" | "),
      _createElementVNode("a", {
        class: _normalizeClass([{ activeT: _ctx.active_tab== 'interest_payments'}, "myTabs"]),
        href: "#",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (this.$emit('myinv_change',_ctx.myinv_id,'interest_payments')))
      }, "Interest & Payment", 2),
      _createTextVNode(" | "),
      _createElementVNode("a", {
        class: _normalizeClass([{ activeT: _ctx.active_tab== 'myinv_register'}, "myTabs"]),
        href: "#",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (this.$emit('myinv_change',_ctx.myinv_id,'myinv_register')))
      }, "Register", 2),
      _createTextVNode(" | "),
      _createElementVNode("a", {
        class: _normalizeClass([{ activeT: _ctx.active_tab== 'myinv_loan_note'}, "myTabs"]),
        href: "#",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (this.$emit('myinv_change',_ctx.myinv_id,'myinv_loan_note')))
      }, "Loan Note", 2),
      _createTextVNode(" | "),
      _createElementVNode("a", {
        class: _normalizeClass([{ activeT: _ctx.active_tab== 'docs'}, "myTabs"]),
        href: "#",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (this.$emit('myinv_change',_ctx.myinv_id,'docs')))
      }, "Documents", 2)
    ])
  ]))
}