
  import { Options, Vue } from 'vue-class-component';
  import MyinvBasic from '@/components/myinvestments/MyinvBasic.vue';
  import Common from '@/services/common.js';
  import moment from 'moment';  
   
  @Options({
    
    props: {
      active_tab:		String,
      myinv_id: 		Number, 
      doc_type:     String, 
    },

    components: {  
      'basic' : MyinvBasic,   
    },


  })
  export default class MyinvDocs extends Vue {
    active_tab;  
    myinv_id; 
    local_storage :any= {};


    docs :any =  []; 
    doc_type  ; 
    timer :any= undefined;

    
    data () {      
      return {  
        active_tab:this.active_tab,
        myinv_id:this.myinv_id, 
        doc_type:this.doc_type,  
      } 
    }    

    created() {    
      this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));   
      return true;
    }

    mounted() {
      this.myinv_id ;
      this.active_tab; 
      this.doc_type;       
      this.get_documents('');  
    }  


    open_link(id){
      var link = (document.getElementById("URL"+id) as HTMLInputElement).value; 
      window.open(link,'_blank');
    } 
    get_documents(search) {   
        Common.get_myinvestment_documents(this.local_storage['access-token'],this.myinv_id,this.doc_type,search)
            .then(response => {
                this.docs=[]; 
                if (typeof response.data !== 'undefined' && response.data.success == 200) {    
                    var sdocs =response.data.docs; 
                    for (let i = 0; i < sdocs.length; i++) {                           
                        sdocs[i]['doc_time'] =moment(sdocs[i]['created_at']).format('DD MMM, YYYY'); 
                    } 
                    this.docs=sdocs;
                }
            }).catch(e => {
              console.log(e)
        });
    }

    textSearch(e:any){
        clearTimeout(this.timer) 
        this.timer = setTimeout(() => {
            this.get_documents(e.target.value)
             
        }, 300)
    }


  }
