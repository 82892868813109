
    import { Options, Vue } from 'vue-class-component';
    import Common from '@/services/common.js';
    import moment from 'moment';  

    @Options({ 
        props: {
            myinv_id:     Number,  
        },  
    })
    
    export default class MyinvFeesBasic extends Vue {  
        myinv_id;
        local_storage   :any; 
        basic           :any={}; 
        data () {      
          return {    
            myinv_id    :this.myinv_id, 
            basic       :this.basic, 
          } 
        }    
        created() {    
            this.local_storage=JSON.parse((localStorage.getItem('caprock-local') || "{}"));    
            return true;
        }
        mounted() {
            this.myinv_id;     
            this.get_myinv_basic_info(); 
        }  

        get_myinv_basic_info() { 
            Common.get_myinv_basic_info(this.local_storage['access-token'],this.myinv_id)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        var basic = response.data.basic;

                        basic['investment_valuation'] =  basic['currency_symbol'] + (typeof basic['investment_valuation']!=='undefined' ? basic['investment_valuation'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);

                        basic['investment_amount'] =  basic['currency_symbol'] + (typeof basic['investment_amount']!=='undefined' ? basic['investment_amount'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);

                        basic['return'] =  basic['currency_symbol'] + (typeof basic['return']!=='undefined' ? basic['return'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);


                        basic['not_invested'] =  basic['currency_symbol'] + (typeof basic['not_invested']!=='undefined' ? basic['not_invested'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);

                        basic['growth'] =  basic['currency_symbol'] + (typeof basic['growth']!=='undefined' ? basic['growth'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0);

                        basic['issue_price'] =  basic['currency_symbol'] + ((typeof basic['issue_price']!=='undefined' && isNaN(basic['issue_price'])==false ) ? basic['issue_price'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : basic['issue_price']);

                        basic['yield'] =  (typeof basic['yield']!=='undefined' ? basic['yield'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : 0)+' %'; 

                        basic['principle_paid'] =  basic['currency_symbol'] + ((typeof basic['principle_paid']!=='undefined' && isNaN(basic['principle_paid'])==false ) ? basic['principle_paid'].toLocaleString(undefined,{minimumFractionDigits: 2,maximumFractionDigits: 2}) : basic['principle_paid']);

                        basic['dividend_next_paid']     = moment(basic['dividend_next_paid']).format('DD/MM/YYYY'); 
                        basic['maturity_date']          = moment(basic['maturity_date']).format('DD MMM, YYYY');  
                        basic['date_invested']          = moment(basic['date_invested']).format('DD MMM, YYYY');  
                        


                        this.basic = basic; 
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
    }
